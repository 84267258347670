const LocationData = [
    {
        'City': 'Anaheim',
        'State': 'CA',
        'Id': 'garagedooranaheim.org',
        'Url': 'https://www.garagedooranaheim.org',
        'Phone': '999-999-9999'
    },
    {
        'City': 'Calabasas',
        'State': 'CA',
        'Id': 'garagedoorcalabasas.org',
        'Url': 'https://www.garagedoorcalabasas.org',
        'Phone': '999-999-9999'
    },
    {
        'City': 'Chino',
        'State': 'CA',
        'Id': 'garagedoorchino.org',
        'Url': 'https://www.garagedoorchino.org',
        'Phone': '999-999-9999'
    },
    {
        'City': 'Corona',
        'State': 'CA',
        'Id': 'garagedoorcorona.org',
        'Url': 'https://www.garagedoorcorona.org',
        'Phone': '999-999-9999'
    },
    {
        'City': 'Chino',
        'State': 'CA',
        'Id': 'garagedoorchino.org',
        'Url': 'https://www.garagedoorchino.org',
        'Phone': '999-999-9999'
    },
    {
        'City': 'Glendale',
        'State': 'CA',
        'Id': 'garagedoorglendale.org',
        'Url': 'https://www.garagedoorglendale.org',
        'Phone': '999-999-9999'
    },
    {
        'City': 'Huntington Beach',
        'State': 'CA',
        'Id': 'garagedoorhuntingtonbeach.org',
        'Url': 'https://www.garagedoorhuntingtonbeach.org',
        'Phone': '999-999-9999'
    },
    {
        'City': 'Irvine',
        'State': 'CA',
        'Id': 'garagedoorirvine.org',
        'Url': 'https://www.garagedoorirvine.org',
        'Phone': '999-999-9999'
    },
    {
        'City': 'Laguna Niguel',
        'State': 'CA',
        'Id': 'garagedoorlagunaniguel.org',
        'Url': 'https://www.garagedoorlagunaniguel.org',
        'Phone': '999-999-9999'
    },
    {
        'City': 'Long Beach',
        'State': 'CA',
        'Id': 'garagedoorlongbeach.org',
        'Url': 'https://www.garagedoorlongbeach.org',
        'Phone': '999-999-9999'
    },
    {
        'City': 'Moreno Valley',
        'State': 'CA',
        'Id': 'garagedoormorenovalley.org',
        'Url': 'https://www.garagedoormorenovalley.org',
        'Phone': '999-999-9999'
    },
    {
        'City': 'Ontario',
        'State': 'CA',
        'Id': 'garagedoorontario.org',
        'Url': 'https://www.garagedoorontario.org',
        'Phone': '999-999-9999'
    },
    {
        'City': 'Oxnard',
        'State': 'CA',
        'Id': 'garagedooroxnard.org',
        'Url': 'https://www.garagedooroxnard.org',
        'Phone': '999-999-9999'
    },
    {
        'City': 'Pasadena',
        'State': 'CA',
        'Id': 'garagedoorpasadena.org',
        'Url': 'https://www.garagedoorpasadena.org',
        'Phone': '999-999-9999'
    },
    {
        'City': 'Pomona',
        'State': 'CA',
        'Id': 'garagedoorpomona.org',
        'Url': 'https://www.garagedoorpomona.org',
        'Phone': '999-999-9999'
    },
    {
        'City': 'Rancho Cucamonga',
        'State': 'CA',
        'Id': 'garagedoorranchocucamonga.org',
        'Url': 'https://www.garagedoorranchocucamonga.org',
        'Phone': '999-999-9999'
    },
    {
        'City': 'Redlands',
        'State': 'CA',
        'Id': 'garagedoorredlands.org',
        'Url': 'https://www.garagedoorredlands.org',
        'Phone': '999-999-9999'
    },
    {
        'City': 'Rialto',
        'State': 'CA',
        'Id': 'garagedoorrialto.org',
        'Url': 'https://www.garagedoorrialto.org',
        'Phone': '999-999-9999'
    },
    {
        'City': 'Riverside',
        'State': 'CA',
        'Id': 'garagedoorriverside.org',
        'Url': 'https://www.garagedoorriverside.org',
        'Phone': '999-999-9999'
    },
    {
        'City': 'San Bernardino',
        'State': 'CA',
        'Id': 'garagedoorsanbernardino.org',
        'Url': 'https://www.garagedoorsanbernardino.org',
        'Phone': '999-999-9999'
    },
    {
        'City': 'San Clemente',
        'State': 'CA',
        'Id': 'garagedoorsanclemente.org',
        'Url': 'https://www.garagedoorsanclemente.org',
        'Phone': '999-999-9999'
    },
    {
        'City': 'Santa Ana',
        'State': 'CA',
        'Id': 'garagedoorsantaana.org',
        'Url': 'https://www.garagedoorsantaana.org',
        'Phone': '999-999-9999'
    },
    {
        'City': 'Santa Clarita',
        'State': 'CA',
        'Id': 'garagedoorsantaclarita.org',
        'Url': 'https://www.garagedoorsantaclarita.org',
        'Phone': '999-999-9999'
    },
    {
        'City': 'Santa Monica',
        'State': 'CA',
        'Id': 'garagedoorsantamonica.org',
        'Url': 'https://www.garagedoorsantamonica.org',
        'Phone': '999-999-9999'
    },
    {
        'City': 'Simi Valley',
        'State': 'CA',
        'Id': 'garagedoorsimivalley.org',
        'Url': 'https://www.garagedoorsimivalley.org',
        'Phone': '999-999-9999'
    },
    {
        'City': 'Temecula',
        'State': 'CA',
        'Id': 'garagedoortemecula.org',
        'Url': 'https://www.garagedoortemecula.org',
        'Phone': '999-999-9999'
    },
    {
        'City': 'Thousand Oaks',
        'State': 'CA',
        'Id': 'garagedoorthousandoaks.org',
        'Url': 'https://www.garagedoorthousandoaks.org',
        'Phone': '999-999-9999'
    },
    {
        'City': 'Torrance',
        'State': 'CA',
        'Id': 'garagedoortorrance.org',
        'Url': 'https://www.garagedoortorrance.org',
        'Phone': '999-999-9999'
    },
    {
        'City': 'Ventura',
        'State': 'CA',
        'Id': 'garagedoorventura.org',
        'Url': 'https://www.garagedoorventura.org',
        'Phone': '999-999-9999'
    }
]
export default LocationData;
