const LocationReviewsData = [
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Ronald Goodwin",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Holly R.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.9,
        "customer_name": "Shannon Dixon",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Pamela B.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.8,
        "customer_name": "Mary Wood",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jose M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.8,
        "customer_name": "Catherine Kennedy",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Tasha S.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-25",
        "review_rate": 5.0,
        "customer_name": "Derrick Kirby",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Lisa H.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.9,
        "customer_name": "Robert Hernandez",
        "service_type": "Garage Door Opener",
        "tech_name": "Danielle J.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Jason Bates",
        "service_type": "Garage Door Services",
        "tech_name": "Jason R.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-22",
        "review_rate": 5.0,
        "customer_name": "Kayla Thomas MD",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Gilbert W.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-10",
        "review_rate": 5.0,
        "customer_name": "Olivia Griffin",
        "service_type": "Garage Door Off Track",
        "tech_name": "Samantha F.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Lawrence Martin",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Michael W.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.8,
        "customer_name": "Javier Gonzalez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jeffrey R.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Thomas Watson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Nicole C.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Susan Harvey",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Samantha W.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-20",
        "review_rate": 5.0,
        "customer_name": "Michael Allen",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Joseph S.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Kyle Salazar",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Ryan M.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-15",
        "review_rate": 5.0,
        "customer_name": "Melissa Fuller",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Amber B.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.9,
        "customer_name": "John Hernandez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Michael W.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-03",
        "review_rate": 5.0,
        "customer_name": "Eugene Simmons",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Joseph M.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-11",
        "review_rate": 5.0,
        "customer_name": "Patrick Campos",
        "service_type": "Garage Door Off Track",
        "tech_name": "Cathy B.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.9,
        "customer_name": "Christina Meyers",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michael W.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Matthew Hart",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Charles H.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Scott Buchanan",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Travis F.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "John Diaz",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Adriana G.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Nicholas Kent",
        "service_type": "Garage Door Services",
        "tech_name": "Carrie B.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.8,
        "customer_name": "Suzanne Saunders",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kevin F.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-09",
        "review_rate": 5.0,
        "customer_name": "Chelsea Cooper",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jason T.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.8,
        "customer_name": "Timothy Mooney",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jessica G.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-26",
        "review_rate": 5.0,
        "customer_name": "Mark Bell",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Christopher E.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-03",
        "review_rate": 5.0,
        "customer_name": "Aaron Newman",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Andrew F.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Bruce Acosta",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Stephen B.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Jane Martinez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Brian H.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.9,
        "customer_name": "Rebecca Woods",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Debbie G.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-16",
        "review_rate": 5.0,
        "customer_name": "Maria James",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Brandy H.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-03",
        "review_rate": 5.0,
        "customer_name": "Nicholas Sims",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Christopher W.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-27",
        "review_rate": 5.0,
        "customer_name": "Marvin Williams",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kathryn G.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.8,
        "customer_name": "Mike Tran",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Troy O.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-12",
        "review_rate": 5.0,
        "customer_name": "Todd West",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Maurice L.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-01",
        "review_rate": 5.0,
        "customer_name": "Evan Campbell",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Charles C.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.9,
        "customer_name": "Lynn Smith",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Matthew J.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Sean Smith",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Melinda B.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-02",
        "review_rate": 5.0,
        "customer_name": "David Rodriguez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Andrea C.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Rebecca Carroll",
        "service_type": "Custom Garage Door Design",
        "tech_name": "David R.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Paul Stevenson",
        "service_type": "Garage Door Opener",
        "tech_name": "Christian M.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.8,
        "customer_name": "Kimberly Wilson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Samantha P.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Marissa Hopkins",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Abigail D.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Kaitlyn Gordon",
        "service_type": "Custom Garage Door Design",
        "tech_name": "James N.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-18",
        "review_rate": 5.0,
        "customer_name": "Marcus Anderson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kristi M.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-12",
        "review_rate": 5.0,
        "customer_name": "Austin Moreno",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Laura J.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.8,
        "customer_name": "Connor Wolf",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Christopher T.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Deanna Wallace",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Gabrielle M.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-06",
        "review_rate": 5.0,
        "customer_name": "Kenneth Kelley",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kelly M.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Alexandra Knight DDS",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Scott C.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "Ruth Cardenas",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Sarah F.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Leslie Kirby",
        "service_type": "Garage Door Off Track",
        "tech_name": "Keith G.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Danielle Johnson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "James M.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-01",
        "review_rate": 5.0,
        "customer_name": "Gregory Porter",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "William S.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.8,
        "customer_name": "Monica Lewis",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Paula L.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "David Henderson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Heather R.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-27",
        "review_rate": 5.0,
        "customer_name": "Jeremiah Rios",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Timothy H.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "Barbara Griffith",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Brittany M.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Stephanie Hanson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Alexis A.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-14",
        "review_rate": 5.0,
        "customer_name": "Nicole Martin",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Cheryl G.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Mr. Joseph Lopez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Allison H.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.8,
        "customer_name": "Randy Nolan",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Lisa M.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Angela Smith",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Shane H.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.8,
        "customer_name": "Eric Carney",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Stephanie F.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Nancy Mclaughlin",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kiara W.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Raymond Jimenez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Angela E.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Julia Anthony",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Vincent M.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.8,
        "customer_name": "Holly Clark",
        "service_type": "Garage Door Off Track",
        "tech_name": "Daniel R.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.8,
        "customer_name": "Jessica Navarro",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Sean M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-31",
        "review_rate": 5.0,
        "customer_name": "Kendra Burns",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Thomas B.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.8,
        "customer_name": "Justin Velez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Cheryl H.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Jennifer Bradley",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Christina T.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Raymond Brooks",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Nicole B.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.9,
        "customer_name": "Roger Haynes",
        "service_type": "Garage Door Off Track",
        "tech_name": "Dan W.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Alexis Berg",
        "service_type": "Garage Door Off Track",
        "tech_name": "John H.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Dr. Michael Christensen",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "William H.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.8,
        "customer_name": "Laura Williams",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Dave H.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Anthony Hill",
        "service_type": "Garage Door Installation",
        "tech_name": "David L.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Jamie Mullins",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Richard G.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Jennifer Hernandez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Christopher H.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-11",
        "review_rate": 5.0,
        "customer_name": "Andrew Fox",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Alejandro B.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-06",
        "review_rate": 5.0,
        "customer_name": "Jose Moore",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Sarah D.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.8,
        "customer_name": "Lisa Norris",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jeremy A.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "Colin Monroe",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Charles P.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Joanne Gibbs",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "David C.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.8,
        "customer_name": "Billy West",
        "service_type": "Garage Door Repair",
        "tech_name": "Kimberly W.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-03",
        "review_rate": 5.0,
        "customer_name": "Shannon Jones",
        "service_type": "Garage Door Services",
        "tech_name": "Aaron S.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-25",
        "review_rate": 5.0,
        "customer_name": "Victoria Anderson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "John W.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Kimberly Jones",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Laura O.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.8,
        "customer_name": "Margaret Logan",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kathleen B.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-17",
        "review_rate": 5.0,
        "customer_name": "Amanda Rollins",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Lawrence R.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Peter Rodriguez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "John O.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-01",
        "review_rate": 5.0,
        "customer_name": "Anthony Duncan",
        "service_type": "Garage Door Off Track",
        "tech_name": "Amber M.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-23",
        "review_rate": 5.0,
        "customer_name": "Nicole Shea",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Mark T.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.9,
        "customer_name": "Wanda Morgan",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Calvin L.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.9,
        "customer_name": "Monique Romero",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jesse D.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Angela Martin",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Brittany C.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.8,
        "customer_name": "Karen Rodriguez",
        "service_type": "Garage Door Installation",
        "tech_name": "Lance K.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.8,
        "customer_name": "Danielle Kirk",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jake C.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Teresa Henry",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Andre D.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-04",
        "review_rate": 5.0,
        "customer_name": "Jason Juarez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Nicole H.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.9,
        "customer_name": "Theresa Gordon",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Laura A.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-09",
        "review_rate": 5.0,
        "customer_name": "Stephanie Miller",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Alexis M.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-13",
        "review_rate": 5.0,
        "customer_name": "David Gonzalez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Teresa C.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Sydney Aguilar",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "William B.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Julian Gomez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Charles P.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.8,
        "customer_name": "Travis Vaughn",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kevin D.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-07",
        "review_rate": 5.0,
        "customer_name": "William Patterson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Scott V.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-01",
        "review_rate": 5.0,
        "customer_name": "Daniel Banks",
        "service_type": "Garage Door Installation",
        "tech_name": "Erin C.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-22",
        "review_rate": 5.0,
        "customer_name": "Monica Irwin",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Joel Y.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Dr. Kelly James DVM",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Lisa R.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Jessica Melendez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Christine J.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-18",
        "review_rate": 5.0,
        "customer_name": "Brittany Hawkins",
        "service_type": "Garage Door Repair",
        "tech_name": "Victoria S.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.8,
        "customer_name": "Kelsey Harper",
        "service_type": "Garage Door Repair",
        "tech_name": "Erin H.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.9,
        "customer_name": "Charles Williamson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Ryan H.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-30",
        "review_rate": 5.0,
        "customer_name": "Andrea Rosales",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Danielle S.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-07",
        "review_rate": 5.0,
        "customer_name": "Gary Crawford",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Allison H.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Angela Hoover",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kenneth G.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Paula Barber",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "John B.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-09",
        "review_rate": 5.0,
        "customer_name": "Michele Henderson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Heidi N.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.8,
        "customer_name": "Catherine Harris",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Linda M.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "Angela Roberts",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jacob R.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "Connie Esparza",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jessica C.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-11",
        "review_rate": 5.0,
        "customer_name": "Susan Marshall",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Andrea N.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-26",
        "review_rate": 5.0,
        "customer_name": "Kathleen Mendez",
        "service_type": "Garage Door Installation",
        "tech_name": "Angela B.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Anthony Sanders",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jason L.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "Stanley Rodriguez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jonathan M.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-11",
        "review_rate": 5.0,
        "customer_name": "Kathy Hill",
        "service_type": "Garage Door Repair",
        "tech_name": "Nicholas M.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.8,
        "customer_name": "Angela Taylor",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "John G.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Robyn Moran",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Maria B.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.9,
        "customer_name": "Brittany Black",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "James D.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-07",
        "review_rate": 5.0,
        "customer_name": "Anthony Richardson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Bobby B.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.8,
        "customer_name": "Dr. Raymond Alvarez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Laura W.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-21",
        "review_rate": 5.0,
        "customer_name": "Megan Carter",
        "service_type": "Garage Door Insulation",
        "tech_name": "Samantha C.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Scott Campbell",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Allison B.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-08",
        "review_rate": 5.0,
        "customer_name": "Susan Johnson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Suzanne A.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-26",
        "review_rate": 5.0,
        "customer_name": "Brandon Hendricks",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jennifer H.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-27",
        "review_rate": 5.0,
        "customer_name": "David Clark",
        "service_type": "Garage Door Insulation",
        "tech_name": "Stacy H.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Justin Obrien",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Joseph S.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.8,
        "customer_name": "Erica Morris",
        "service_type": "Garage Door Insulation",
        "tech_name": "Karen J.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.8,
        "customer_name": "Joy Holloway",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Claudia H.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Lonnie Cross",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Dana R.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Michael Young",
        "service_type": "Garage Door Off Track",
        "tech_name": "Martin C.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Kenneth Romero",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jennifer H.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-13",
        "review_rate": 5.0,
        "customer_name": "Mrs. Linda Coleman MD",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Ashley B.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Michelle Meyer",
        "service_type": "Garage Door Opener",
        "tech_name": "Thomas V.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Renee Lang",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kayla W.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.9,
        "customer_name": "Brian Dunn",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Eric J.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.8,
        "customer_name": "Cynthia Madden",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Ethan J.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Jillian Church",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jerry W.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "David Brown",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "John M.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.8,
        "customer_name": "Allison Nicholson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Antonio T.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Tristan Salinas",
        "service_type": "Garage Door Services",
        "tech_name": "Troy R.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.8,
        "customer_name": "Bradley Williams",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Michael M.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.8,
        "customer_name": "Rachel Anderson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Chad B.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.8,
        "customer_name": "John Preston",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Brian M.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Noah Pierce",
        "service_type": "Garage Door Repair",
        "tech_name": "Debra C.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.8,
        "customer_name": "Michael Marshall",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Tony H.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.8,
        "customer_name": "Xavier Brown",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Mason W.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.9,
        "customer_name": "Megan Lawrence",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Austin K.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.8,
        "customer_name": "Phillip Suarez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Linda M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.8,
        "customer_name": "Brittney Williams",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Katherine W.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.8,
        "customer_name": "Evan Lane",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Justin C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-02",
        "review_rate": 5.0,
        "customer_name": "Peter Adkins",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Emily G.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Alexander Smith",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Alex H.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "Toni Henderson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Valerie S.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-18",
        "review_rate": 5.0,
        "customer_name": "James Brown",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kimberly K.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Kevin King",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Katherine B.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Sara Peterson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Erin T.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Sheila Kim",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Holly C.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-29",
        "review_rate": 5.0,
        "customer_name": "David Chandler",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Judith R.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.8,
        "customer_name": "Gregory Smith",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Carl W.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Emily Taylor",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Laura W.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Paula Brooks",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Taylor C.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Julie Davila",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "John J.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.8,
        "customer_name": "Wanda Skinner",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Dwayne F.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-16",
        "review_rate": 5.0,
        "customer_name": "Brett Carter",
        "service_type": "Garage Door Installation",
        "tech_name": "Kelly R.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.8,
        "customer_name": "Emily Cantrell",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Ryan P.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "David Robertson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Brandon D.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.8,
        "customer_name": "Thomas Archer",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kevin G.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-18",
        "review_rate": 5.0,
        "customer_name": "Jennifer Guerrero",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Erik J.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.8,
        "customer_name": "Misty Allen",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Debra A.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-17",
        "review_rate": 5.0,
        "customer_name": "Amy Brown",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Scott H.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.8,
        "customer_name": "Christopher Berry",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "William C.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.8,
        "customer_name": "Brian Bailey",
        "service_type": "Garage Door Insulation",
        "tech_name": "Sandra W.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.8,
        "customer_name": "David Gomez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Brian C.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Bradley Nguyen",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jeffery W.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "Erin Thomas",
        "service_type": "Garage Door Services",
        "tech_name": "Kimberly W.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Annette Vega",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Katherine R.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-16",
        "review_rate": 5.0,
        "customer_name": "Denise Clements",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Dustin W.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Diane Jensen",
        "service_type": "Garage Door Repair",
        "tech_name": "Jacqueline W.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "John Gray",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jonathon D.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.8,
        "customer_name": "Priscilla Sawyer",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Timothy F.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-24",
        "review_rate": 5.0,
        "customer_name": "Cassandra Smith",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Howard A.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "Brittney Harris",
        "service_type": "Garage Door Services",
        "tech_name": "Joseph M.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Kendra Hansen",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Donald F.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.8,
        "customer_name": "William Nelson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Alexa T.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.9,
        "customer_name": "Tara Cole",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Matthew B.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-30",
        "review_rate": 5.0,
        "customer_name": "Edward Simon",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Emily K.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Tammy Hammond",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "David H.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.8,
        "customer_name": "Tiffany Webb",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Ryan L.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-21",
        "review_rate": 5.0,
        "customer_name": "Eric Parker",
        "service_type": "Garage Door Installation",
        "tech_name": "Natalie B.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.8,
        "customer_name": "Emily Thomas",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jeffrey M.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.8,
        "customer_name": "April Martinez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Ann M.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Stephen Thompson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Rebecca S.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.8,
        "customer_name": "Mark Lopez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Joy F.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.8,
        "customer_name": "Dr. Richard Thompson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Dorothy H.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-15",
        "review_rate": 5.0,
        "customer_name": "George Hartman MD",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Nichole H.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Monica Garcia",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Rhonda G.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "William Anderson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Marcus W.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.8,
        "customer_name": "Tonya Patterson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Clifford K.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.9,
        "customer_name": "Tonya Trevino",
        "service_type": "Garage Door Installation",
        "tech_name": "James K.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.8,
        "customer_name": "Dawn Haas",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Brenda C.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.8,
        "customer_name": "Jessica Burke",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Eric J.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-12",
        "review_rate": 5.0,
        "customer_name": "Kathryn Christensen",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Ashley J.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-30",
        "review_rate": 5.0,
        "customer_name": "Jordan Rodriguez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Nicholas P.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.8,
        "customer_name": "Stephanie Roman",
        "service_type": "Garage Door Repair",
        "tech_name": "James C.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Thomas Herrera",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michael B.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Benjamin Trujillo",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Albert A.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.8,
        "customer_name": "Adrian Stewart",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Rodney S.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-04",
        "review_rate": 5.0,
        "customer_name": "Christopher Winters",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jessica J.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Olivia Dorsey",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Brenda R.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.8,
        "customer_name": "Shannon Juarez",
        "service_type": "Garage Door Services",
        "tech_name": "Elizabeth R.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Peggy Stephens",
        "service_type": "Garage Door Installation",
        "tech_name": "Kristina C.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-29",
        "review_rate": 5.0,
        "customer_name": "Neil Crawford",
        "service_type": "Garage Door Opener",
        "tech_name": "Caitlyn C.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Deborah Collins",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Dana J.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Krista Alexander",
        "service_type": "Garage Door Services",
        "tech_name": "Andrea C.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Dennis Lopez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Katrina V.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.9,
        "customer_name": "John Jackson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Judith A.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-09-28",
        "review_rate": 5.0,
        "customer_name": "Carolyn Moon",
        "service_type": "Garage Door Off Track",
        "tech_name": "Johnny F.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Susan Santiago",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Alan P.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.8,
        "customer_name": "Michael Gonzalez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Matthew R.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Peterson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Henry D.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.9,
        "customer_name": "Kevin Barber",
        "service_type": "Garage Door Opener",
        "tech_name": "Walter W.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.8,
        "customer_name": "Cheryl Blake",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jennifer W.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Crystal Brown",
        "service_type": "Garage Door Services",
        "tech_name": "Kenneth S.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.8,
        "customer_name": "Maureen Reyes",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Leah K.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-01",
        "review_rate": 5.0,
        "customer_name": "Eddie Martin",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jason T.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-20",
        "review_rate": 5.0,
        "customer_name": "Frank Martinez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "James A.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-19",
        "review_rate": 5.0,
        "customer_name": "Paula Peterson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Austin E.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Christopher Mason",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Barbara M.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-05",
        "review_rate": 5.0,
        "customer_name": "James Chavez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Victoria W.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-13",
        "review_rate": 5.0,
        "customer_name": "Adam Scott",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jose A.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Wanda Coleman",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Catherine H.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "David Reed",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Mary H.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Ryan Turner",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Andrew D.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.8,
        "customer_name": "Melissa Prince",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Allison G.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Laura Garcia",
        "service_type": "Garage Door Installation",
        "tech_name": "Ashley H.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-31",
        "review_rate": 5.0,
        "customer_name": "Theresa Mckenzie",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Melvin W.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "Miss Amber Williams DDS",
        "service_type": "Garage Door Repair",
        "tech_name": "Hunter M.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Ruth Mack",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Robert P.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Chad Woods",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Mark C.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.8,
        "customer_name": "Melanie Montgomery",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Alyssa C.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Anita Humphrey",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Ashley W.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-18",
        "review_rate": 5.0,
        "customer_name": "David Webb",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kelly D.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-26",
        "review_rate": 5.0,
        "customer_name": "Austin Bryant",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jasmine J.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Stacie Flores",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Priscilla C.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.9,
        "customer_name": "Scott Watts",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Susan H.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.9,
        "customer_name": "Deanna Davis",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jacob W.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.8,
        "customer_name": "Colleen Harrison",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Charles M.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.8,
        "customer_name": "Elaine Bryant",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Brian B.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Sherry Watson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Phyllis J.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.8,
        "customer_name": "Sheri Jones",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Maria R.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.8,
        "customer_name": "Adam Noble",
        "service_type": "Garage Door Installation",
        "tech_name": "Gregory G.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-07",
        "review_rate": 5.0,
        "customer_name": "James Gilbert",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Lisa K.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-02",
        "review_rate": 5.0,
        "customer_name": "Megan Mendoza",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michelle F.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.8,
        "customer_name": "Amy Jennings",
        "service_type": "Garage Door Off Track",
        "tech_name": "Danny J.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "James Bray",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Joel R.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Tracy Bradford",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Donald C.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Michael Wilkerson",
        "service_type": "Garage Door Opener",
        "tech_name": "Jason G.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Alexander Meyers",
        "service_type": "Garage Door Repair",
        "tech_name": "Heather M.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Jody Larson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Michelle S.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "Raymond Hart",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Robert B.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.9,
        "customer_name": "Susan Mcintosh",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Martin T.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Daniel Conner",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Robert L.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-20",
        "review_rate": 5.0,
        "customer_name": "Ivan Pratt",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michelle D.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Veronica Stewart",
        "service_type": "Garage Door Opener",
        "tech_name": "Joshua N.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Julian Kerr",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Judith P.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.9,
        "customer_name": "James Singh",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Sherry T.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Zoe Bruce",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Erica F.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Bobby Juarez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Amy G.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.8,
        "customer_name": "Guy Robbins",
        "service_type": "Garage Door Off Track",
        "tech_name": "Brian M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Tommy Alvarez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Gloria H.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-25",
        "review_rate": 5.0,
        "customer_name": "Christopher Cochran",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Timothy M.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Susan Powers",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kaitlin M.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Castaneda",
        "service_type": "Garage Door Insulation",
        "tech_name": "Mary F.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Linda Ibarra",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Steven H.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-03",
        "review_rate": 5.0,
        "customer_name": "Jason Rubio",
        "service_type": "Garage Door Services",
        "tech_name": "Drew V.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-27",
        "review_rate": 5.0,
        "customer_name": "Rebecca Fernandez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Brittany T.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-25",
        "review_rate": 5.0,
        "customer_name": "Ronnie Campbell",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Christopher S.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.8,
        "customer_name": "Kellie Jones",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Alyssa M.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.8,
        "customer_name": "Bruce Torres",
        "service_type": "Garage Door Insulation",
        "tech_name": "Deborah G.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Brianna Higgins",
        "service_type": "Garage Door Repair",
        "tech_name": "Holly C.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.8,
        "customer_name": "Megan Martinez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Melissa A.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.8,
        "customer_name": "Carrie Myers",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Bobby M.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Juan Hunt",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jennifer M.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Tammy Decker",
        "service_type": "Garage Door Repair",
        "tech_name": "James M.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-30",
        "review_rate": 5.0,
        "customer_name": "Trevor Parsons",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "William G.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.8,
        "customer_name": "Andrea Roberts",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Judith B.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Brandon Arias",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Lisa J.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.9,
        "customer_name": "Samantha Brown",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Erik G.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.8,
        "customer_name": "Richard Hernandez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Nancy J.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-01",
        "review_rate": 5.0,
        "customer_name": "Dana Martinez",
        "service_type": "Garage Door Opener",
        "tech_name": "James F.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Jacob Miller",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Tammy G.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-18",
        "review_rate": 5.0,
        "customer_name": "Stacey White",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Gina H.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.8,
        "customer_name": "Michelle Johnson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Peggy H.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Sean Newman",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Michael R.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-28",
        "review_rate": 5.0,
        "customer_name": "Andrew Nunez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Cassie P.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.9,
        "customer_name": "Kathryn Schwartz",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Matthew W.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "William Adkins",
        "service_type": "Garage Door Opener",
        "tech_name": "Brian D.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.8,
        "customer_name": "Sarah Torres",
        "service_type": "Garage Door Repair",
        "tech_name": "Mikayla C.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.8,
        "customer_name": "Lori Alvarez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Suzanne H.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Clark",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kaitlyn M.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-01",
        "review_rate": 5.0,
        "customer_name": "Dale Wallace",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Melissa T.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.8,
        "customer_name": "Sandra Garcia",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jessica S.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-09",
        "review_rate": 5.0,
        "customer_name": "Anna Rodriguez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Dawn S.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Ashley Watkins",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Brittany S.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.8,
        "customer_name": "Brian Guzman",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Erin G.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.8,
        "customer_name": "Jeffery Rodriguez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jennifer R.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.8,
        "customer_name": "Craig Hudson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "James B.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Mark Ramsey",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Donald R.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Erik Gonzales",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Adam L.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "James Smith",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Hannah L.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Michael Brown",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Rebecca F.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Robert Knight",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Stephanie M.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Julie Hughes",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jonathon G.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Danielle Aguilar",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Patrick L.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-27",
        "review_rate": 5.0,
        "customer_name": "Donald Levy",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Rhonda D.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-20",
        "review_rate": 5.0,
        "customer_name": "John Sanders",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Laura G.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.8,
        "customer_name": "Emma Frank",
        "service_type": "Garage Door Installation",
        "tech_name": "Todd F.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Jill King",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Joanna J.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-08",
        "review_rate": 5.0,
        "customer_name": "Steven Hull",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Michelle M.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.8,
        "customer_name": "Carl Reilly",
        "service_type": "Garage Door Off Track",
        "tech_name": "Keith B.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Kevin Hayden",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Reginald A.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Andrea Guzman",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Cynthia L.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.8,
        "customer_name": "Carmen Schultz",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Stephen W.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Charles Patrick",
        "service_type": "Garage Door Off Track",
        "tech_name": "Ashlee S.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Gabriel Marshall",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Hannah L.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Susan Little",
        "service_type": "Garage Door Repair",
        "tech_name": "Andrew B.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Carlos Parks",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Amy J.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.8,
        "customer_name": "Kendra Sanders",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jacob C.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.8,
        "customer_name": "Daniel Drake",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Elijah R.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "Gail Wells",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Brian C.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Katherine Haas",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Matthew K.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.8,
        "customer_name": "Sarah Ramirez",
        "service_type": "Garage Door Services",
        "tech_name": "Andrea W.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.9,
        "customer_name": "Kimberly Ward",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Brenda S.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-16",
        "review_rate": 5.0,
        "customer_name": "Thomas Edwards",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Joseph R.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Leah Davis",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Rachel G.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Patricia Banks",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Vanessa B.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "Anna Fletcher",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "James J.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-06",
        "review_rate": 5.0,
        "customer_name": "Gary Rangel",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Robert B.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Ashley Vazquez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Michael H.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Rachel Beck",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Ashley W.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Tammy Ortega",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kyle C.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-28",
        "review_rate": 5.0,
        "customer_name": "Jay Gamble",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Derrick M.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-10",
        "review_rate": 5.0,
        "customer_name": "Kristen Gomez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Craig R.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.8,
        "customer_name": "Kimberly Barnes",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jasmine E.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Linda Anderson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Michael Y.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Holly Edwards",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Robin H.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Dylan Hutchinson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Elizabeth M.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Benjamin Webb",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Melissa S.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Anthony Richardson",
        "service_type": "Garage Door Repair",
        "tech_name": "James S.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-14",
        "review_rate": 5.0,
        "customer_name": "Shawn Miller",
        "service_type": "Garage Door Opener",
        "tech_name": "Chad B.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.8,
        "customer_name": "Jordan Graham",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Travis M.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.8,
        "customer_name": "Sarah Blackburn",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Susan G.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.8,
        "customer_name": "Danielle Hubbard",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Lisa R.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-07",
        "review_rate": 5.0,
        "customer_name": "Samantha Rogers",
        "service_type": "Garage Door Insulation",
        "tech_name": "Joseph E.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.8,
        "customer_name": "Yesenia Wolfe",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "James S.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-26",
        "review_rate": 5.0,
        "customer_name": "Walter Fox",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Shawn C.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Melanie Valencia",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jennifer S.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-11",
        "review_rate": 5.0,
        "customer_name": "Mrs. Diana Lopez",
        "service_type": "Garage Door Opener",
        "tech_name": "Sean R.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-14",
        "review_rate": 5.0,
        "customer_name": "Charles Webb",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Cynthia J.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.8,
        "customer_name": "Ricky Wilson",
        "service_type": "Garage Door Opener",
        "tech_name": "Matthew F.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-24",
        "review_rate": 5.0,
        "customer_name": "Alex Blevins",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Sheila M.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-19",
        "review_rate": 5.0,
        "customer_name": "Gary Lopez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Donald M.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-07",
        "review_rate": 5.0,
        "customer_name": "Amber Calhoun",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kristie G.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Michelle Hernandez",
        "service_type": "Garage Door Installation",
        "tech_name": "Jacob W.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.8,
        "customer_name": "Matthew Reed",
        "service_type": "Garage Door Off Track",
        "tech_name": "Destiny M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.8,
        "customer_name": "Mark Sandoval",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Vanessa C.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Michael Medina",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Eric M.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "Beverly Collins",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jason A.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Nicole Bernard",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jacob E.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-02",
        "review_rate": 5.0,
        "customer_name": "Dustin Horne",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jessica A.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-09-27",
        "review_rate": 5.0,
        "customer_name": "Ashley Carrillo",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Joshua P.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.9,
        "customer_name": "Timothy Goodwin",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Chad E.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Kimberly Duncan",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Christine D.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-06",
        "review_rate": 5.0,
        "customer_name": "Adrian Crawford",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Lisa L.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "David Morgan",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jacob W.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-14",
        "review_rate": 5.0,
        "customer_name": "Evan Matthews",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Chase R.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Mary Wood",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Crystal P.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.9,
        "customer_name": "Kathleen Edwards DDS",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Lisa A.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-22",
        "review_rate": 5.0,
        "customer_name": "Bradley Patel",
        "service_type": "Garage Door Installation",
        "tech_name": "Corey S.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Dean Webb",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Michael W.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Eric Reese",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Thomas M.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.8,
        "customer_name": "Jordan Andrews",
        "service_type": "Garage Door Off Track",
        "tech_name": "Tina D.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.8,
        "customer_name": "Catherine Day",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kayla F.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Shawn Ramirez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Aaron H.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Donald Simpson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Brian F.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Lawrence Daniel",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Ernest S.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Diane Adkins",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jason S.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-02",
        "review_rate": 5.0,
        "customer_name": "Shane Spears",
        "service_type": "Garage Door Insulation",
        "tech_name": "Pamela M.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Jennifer Hart",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Scott H.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-29",
        "review_rate": 5.0,
        "customer_name": "Crystal Meadows",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Anita S.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.9,
        "customer_name": "Frederick Pitts",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kaitlyn D.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-08",
        "review_rate": 5.0,
        "customer_name": "Maria Thompson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jeffrey J.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Nicole Lee",
        "service_type": "Garage Door Installation",
        "tech_name": "Jeffrey H.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.8,
        "customer_name": "Gregory Cooper",
        "service_type": "Garage Door Insulation",
        "tech_name": "Brandon G.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-05",
        "review_rate": 5.0,
        "customer_name": "Joseph Orr",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Chelsea R.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Jaime Dougherty",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Ruben H.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-10",
        "review_rate": 5.0,
        "customer_name": "John Barron",
        "service_type": "Garage Door Repair",
        "tech_name": "Todd C.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Carla Merritt",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Daniel K.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-19",
        "review_rate": 5.0,
        "customer_name": "Deborah Dean",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jerry W.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Martin Williams",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "John C.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Steven Jones",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Stephen C.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.8,
        "customer_name": "Joseph Bryan",
        "service_type": "Garage Door Off Track",
        "tech_name": "Rachel L.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.8,
        "customer_name": "Richard Morrow",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Donald D.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Lori Moore",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Connie B.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Dr. Christopher Ware PhD",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Melissa W.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Joanna Reyes",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "James B.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-07",
        "review_rate": 5.0,
        "customer_name": "Kimberly Taylor",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Todd P.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Tamara Knight",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Matthew K.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.8,
        "customer_name": "Arthur Jones",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Karen W.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-24",
        "review_rate": 5.0,
        "customer_name": "Gloria Henderson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Philip T.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "Andrea Garcia",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Renee L.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.8,
        "customer_name": "Robert Clark",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Alicia M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "David Mcdowell",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Savannah M.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.8,
        "customer_name": "Christina Foster",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Dennis E.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Randall Huber",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jessica G.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Cannon",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jeffrey D.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Jonathan Fleming",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Emily M.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.8,
        "customer_name": "Michael Cox",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Theresa B.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "David Reynolds",
        "service_type": "Garage Door Opener",
        "tech_name": "John B.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-05",
        "review_rate": 5.0,
        "customer_name": "Monica Graves",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Joseph S.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.8,
        "customer_name": "Cassandra Evans",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Andrea R.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Gregory Mcpherson",
        "service_type": "Garage Door Opener",
        "tech_name": "Chad J.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Michael Roberts",
        "service_type": "Garage Door Opener",
        "tech_name": "Christina M.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Ethan Compton",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Vernon C.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.8,
        "customer_name": "Charles Ward",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jessica W.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Gregory Garcia",
        "service_type": "Garage Door Repair",
        "tech_name": "Dana L.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.9,
        "customer_name": "Frank Herrera",
        "service_type": "Garage Door Installation",
        "tech_name": "Ronnie T.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.8,
        "customer_name": "Michelle Kramer",
        "service_type": "Garage Door Services",
        "tech_name": "Jay A.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.8,
        "customer_name": "Mr. Jacob Lynch",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Maria B.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "William Burns",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Michael T.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-18",
        "review_rate": 5.0,
        "customer_name": "Brian White",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Katie R.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-11",
        "review_rate": 5.0,
        "customer_name": "Laura Neal",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Amy Y.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Gary Diaz",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Chad G.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Edward Harris",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Victoria H.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.8,
        "customer_name": "Robert Howell",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Eric F.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Kelly Williams",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Debra L.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-17",
        "review_rate": 5.0,
        "customer_name": "Robert Joseph",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Robert A.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.9,
        "customer_name": "Daniel Blackwell",
        "service_type": "Garage Door Insulation",
        "tech_name": "Daniel G.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-01",
        "review_rate": 5.0,
        "customer_name": "Miss Rebecca Peterson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Matthew P.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.9,
        "customer_name": "Angela Pearson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Laura W.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-14",
        "review_rate": 5.0,
        "customer_name": "Leah Kelley",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Christopher C.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-11",
        "review_rate": 5.0,
        "customer_name": "Carolyn White",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jonathon L.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-04",
        "review_rate": 5.0,
        "customer_name": "Brian Hayden",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Amanda E.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.8,
        "customer_name": "Jamie Holt",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jenny S.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Sarah Doyle",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Melissa Q.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.8,
        "customer_name": "Rachel Harris",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Edward M.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-25",
        "review_rate": 5.0,
        "customer_name": "James Wagner",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Brenda M.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-07",
        "review_rate": 5.0,
        "customer_name": "Sean Glenn",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Douglas R.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.8,
        "customer_name": "Michael Guzman",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "David R.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Mrs. Karen Wagner",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Christopher P.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Douglas Wilson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "John T.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Jonathan Moore DDS",
        "service_type": "Garage Door Repair",
        "tech_name": "Linda J.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Jodi Lopez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Lucas B.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-23",
        "review_rate": 5.0,
        "customer_name": "Frank Simon",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Laura S.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-08",
        "review_rate": 5.0,
        "customer_name": "Donald Hansen",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Deborah P.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-19",
        "review_rate": 5.0,
        "customer_name": "Keith Reed",
        "service_type": "Garage Door Installation",
        "tech_name": "Monica K.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.9,
        "customer_name": "Alexandra Dennis PhD",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Mark H.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.8,
        "customer_name": "Sean Snyder",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Sue K.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Brenda Campbell",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Nicholas S.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.8,
        "customer_name": "Rachel Phillips",
        "service_type": "Garage Door Off Track",
        "tech_name": "Lawrence C.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-19",
        "review_rate": 5.0,
        "customer_name": "Jason Oconnor",
        "service_type": "Garage Door Off Track",
        "tech_name": "Dylan C.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-30",
        "review_rate": 5.0,
        "customer_name": "Shelley Andrews",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Phillip B.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "James Myers",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Carolyn G.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.8,
        "customer_name": "Melissa Smith",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jordan M.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-05",
        "review_rate": 5.0,
        "customer_name": "Zachary Oconnor",
        "service_type": "Garage Door Opener",
        "tech_name": "Cynthia M.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Stephen Kline",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Whitney D.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-14",
        "review_rate": 5.0,
        "customer_name": "Richard Reyes",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Anthony B.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "Michael Clark",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jeffrey Y.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-22",
        "review_rate": 5.0,
        "customer_name": "Glenn Moore",
        "service_type": "Garage Door Opener",
        "tech_name": "Travis G.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-09",
        "review_rate": 5.0,
        "customer_name": "Daniel Fowler",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Michael J.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.8,
        "customer_name": "Denise Phillips",
        "service_type": "Garage Door Opener",
        "tech_name": "Tyler L.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.8,
        "customer_name": "Samuel Moore",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Lisa B.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.8,
        "customer_name": "Nancy Durham",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jerry O.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-03",
        "review_rate": 5.0,
        "customer_name": "Heather Garza",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jose H.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Brian Cole",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Monica O.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.9,
        "customer_name": "Bailey Chapman",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Vickie D.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Felicia Smith",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Scott S.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Briana Young",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Bailey B.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Hunter",
        "service_type": "Garage Door Opener",
        "tech_name": "Gregory G.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Nathan Smith",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Sarah H.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-22",
        "review_rate": 5.0,
        "customer_name": "Jamie Griffin",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Marcus H.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Brittany Howell",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Sharon G.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.8,
        "customer_name": "Jason Boyd",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Amy G.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-25",
        "review_rate": 5.0,
        "customer_name": "Gregory Hill",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Lynn C.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-24",
        "review_rate": 5.0,
        "customer_name": "Michael Hernandez",
        "service_type": "Garage Door Services",
        "tech_name": "Mark W.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Jonathan Pena",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Deborah B.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Jason Nelson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Adrian P.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.8,
        "customer_name": "Mary Collier",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Sarah M.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.8,
        "customer_name": "Tonya Wiggins",
        "service_type": "Garage Door Insulation",
        "tech_name": "Mike B.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.8,
        "customer_name": "Jacob Leblanc",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Amy O.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "Jacob Lane",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Richard A.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.8,
        "customer_name": "Sarah Michael",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Barbara M.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.8,
        "customer_name": "Susan Ray",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Steven G.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-28",
        "review_rate": 5.0,
        "customer_name": "Danielle Adams",
        "service_type": "Garage Door Off Track",
        "tech_name": "Randall M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.8,
        "customer_name": "Brenda Larsen",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jeremy B.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Rachel Houston",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kimberly R.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Tracy Jackson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Darren S.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.8,
        "customer_name": "Susan Johnson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Aaron R.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Stephanie Scott",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Brad G.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-19",
        "review_rate": 5.0,
        "customer_name": "Julie Andrews",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Deborah J.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Peter Sanders",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Troy S.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Tanya Yoder",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Allen H.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.8,
        "customer_name": "Keith Fisher",
        "service_type": "Garage Door Services",
        "tech_name": "Nicole G.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-28",
        "review_rate": 5.0,
        "customer_name": "Steven White",
        "service_type": "Garage Door Repair",
        "tech_name": "Joshua J.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-16",
        "review_rate": 5.0,
        "customer_name": "Michael Montes V",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Shawn G.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Danielle Patel",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jay D.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Lindsay Vaughn",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jasmine M.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Dr. Alexandra Hanson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Nicole R.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "David Oneill",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Brenda D.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-10",
        "review_rate": 5.0,
        "customer_name": "Roger Brennan",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Rachel Y.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "Amber Johnson",
        "service_type": "Garage Door Services",
        "tech_name": "Shirley B.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.8,
        "customer_name": "Brendan Maldonado",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Angela B.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.8,
        "customer_name": "Stephanie Leonard",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Denise A.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-10",
        "review_rate": 5.0,
        "customer_name": "Ronald Thomas",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Lindsey M.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Matthew Nguyen",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Rebecca B.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Brittney Hughes",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Nancy S.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "Carl Lopez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Lisa N.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "John Lara",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "George H.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Alexander",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Manuel N.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Rachel Brown",
        "service_type": "Garage Door Services",
        "tech_name": "Robert R.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Michele Lee",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Morgan W.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Denise Ward",
        "service_type": "Garage Door Opener",
        "tech_name": "Danielle G.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.8,
        "customer_name": "Mary Cameron",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kristina G.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Rhonda Jacobs",
        "service_type": "Garage Door Off Track",
        "tech_name": "Alec T.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-13",
        "review_rate": 5.0,
        "customer_name": "Maria Carlson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "John M.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.8,
        "customer_name": "Anna Randolph",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kendra W.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-21",
        "review_rate": 5.0,
        "customer_name": "Mark Miller",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Alexander H.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-15",
        "review_rate": 5.0,
        "customer_name": "Karen Summers",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jonathan S.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-21",
        "review_rate": 5.0,
        "customer_name": "Caitlin Elliott",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Karen K.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Steven Walls",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kara O.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.8,
        "customer_name": "Pamela Haas",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jason L.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Jaime Norman",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Chad W.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-18",
        "review_rate": 5.0,
        "customer_name": "Natalie Sanchez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Robert J.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.8,
        "customer_name": "Jennifer Torres",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Elizabeth T.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-31",
        "review_rate": 5.0,
        "customer_name": "Marc Garcia",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Luke S.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Michael Bradley",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "April R.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.8,
        "customer_name": "David Johnson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Noah B.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Brandy Chavez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Matthew H.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Angela Martin",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Yvonne M.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.8,
        "customer_name": "Brittany Norton",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Christina F.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.9,
        "customer_name": "Christian Ramirez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Brenda A.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Joseph Wagner",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Seth V.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Mary Morrison",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Anita M.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-20",
        "review_rate": 5.0,
        "customer_name": "David Jimenez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kayla W.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-14",
        "review_rate": 5.0,
        "customer_name": "Terrance Tran",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Evan O.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Amber Santos",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kyle B.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.8,
        "customer_name": "Tyler Villa",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Noah R.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Jason Mcdaniel",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Robert P.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-27",
        "review_rate": 5.0,
        "customer_name": "Brad Crawford",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jeffrey C.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-31",
        "review_rate": 5.0,
        "customer_name": "Amy Carroll",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Cory S.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Amy Johnson",
        "service_type": "Garage Door Repair",
        "tech_name": "Jessica R.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Andrea Smith",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Tyler R.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.8,
        "customer_name": "Audrey Roach",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Joseph S.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-06",
        "review_rate": 5.0,
        "customer_name": "John Brown",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Nicholas D.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.8,
        "customer_name": "Anthony Navarro",
        "service_type": "Garage Door Opener",
        "tech_name": "Joshua M.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.9,
        "customer_name": "Benjamin Leblanc",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Darryl M.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-09",
        "review_rate": 5.0,
        "customer_name": "Dana Hunter",
        "service_type": "Garage Door Insulation",
        "tech_name": "Nicholas O.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Anne Santiago",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Catherine J.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Zachary Tucker",
        "service_type": "Garage Door Repair",
        "tech_name": "Derek W.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-06",
        "review_rate": 5.0,
        "customer_name": "James Sanchez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Gloria F.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-02",
        "review_rate": 5.0,
        "customer_name": "Kevin Valentine",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jose C.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Ethan Molina",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "David C.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.8,
        "customer_name": "Colin Fuller",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Breanna N.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-17",
        "review_rate": 5.0,
        "customer_name": "Francis Valdez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "James M.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.8,
        "customer_name": "Kendra Castro",
        "service_type": "Garage Door Installation",
        "tech_name": "Amber T.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.8,
        "customer_name": "Debra Marshall",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Timothy D.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Jose Sanchez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jennifer W.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "Debra Reynolds",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Marie N.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Joshua Miles",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jason F.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "Cheyenne Gutierrez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kristin A.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-09",
        "review_rate": 5.0,
        "customer_name": "Jon Castro",
        "service_type": "Garage Door Services",
        "tech_name": "Jaime W.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Lawrence Lee",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Michelle J.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-20",
        "review_rate": 5.0,
        "customer_name": "Tracy Spencer",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Daniel T.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Michael Williams",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Brett S.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.8,
        "customer_name": "Heidi Long",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Ronald W.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.8,
        "customer_name": "Jill Chaney",
        "service_type": "Garage Door Services",
        "tech_name": "John M.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-25",
        "review_rate": 5.0,
        "customer_name": "Virginia Medina DDS",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Tracy P.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-12",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Gray",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Madison S.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.8,
        "customer_name": "Susan Burnett",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Emily J.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.8,
        "customer_name": "Mr. Dennis Stewart",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Angela R.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "David Gonzales",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Lisa A.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Abigail Hurley",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Lisa P.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.8,
        "customer_name": "Terry Smith",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jonathan C.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Sandra Neal",
        "service_type": "Garage Door Repair",
        "tech_name": "Gregory L.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Dennis Lawrence",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Lisa G.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.8,
        "customer_name": "Logan Paul",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michael P.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Adam Calderon",
        "service_type": "Garage Door Services",
        "tech_name": "Debra A.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Nicole Oliver",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kayla S.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.8,
        "customer_name": "Eric Figueroa",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Gregory C.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Thomas Friedman",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Hannah J.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Kathleen Delgado",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Teresa P.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Rebecca Bird",
        "service_type": "Garage Door Insulation",
        "tech_name": "Shawn M.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.8,
        "customer_name": "Kenneth Jenkins",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Becky B.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Adam Gonzalez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Katrina A.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.9,
        "customer_name": "Juan Washington",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Stephanie S.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "David Savage",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Cynthia F.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Alan Walker",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Katie J.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.8,
        "customer_name": "Richard Johnson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Karen B.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Melissa Baird",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Colleen D.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-13",
        "review_rate": 5.0,
        "customer_name": "Amy Silva",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Michele W.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.8,
        "customer_name": "Alejandra Webb",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Stephanie J.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.8,
        "customer_name": "Michelle Caldwell",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Timothy R.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Dawn Hess",
        "service_type": "Garage Door Services",
        "tech_name": "Thomas J.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Amber Shepherd",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Bryan T.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.8,
        "customer_name": "Melissa Thompson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Autumn R.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.8,
        "customer_name": "Joseph Rivera",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Holly B.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Kyle Evans",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Christina B.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.8,
        "customer_name": "Jon White",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Michelle S.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Amy Wang",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Angela G.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-10",
        "review_rate": 5.0,
        "customer_name": "Nicole Graham",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Alan W.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Lisa Goodwin",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Bradley J.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-15",
        "review_rate": 5.0,
        "customer_name": "Theresa Vasquez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Hannah H.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.8,
        "customer_name": "David Martin",
        "service_type": "Garage Door Insulation",
        "tech_name": "Patrick P.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Stephanie Leonard",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Donna M.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "Mark Hernandez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Sherry B.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Sandra Cook",
        "service_type": "Garage Door Installation",
        "tech_name": "Jennifer L.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.8,
        "customer_name": "Jeff Pitts",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Melissa M.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-18",
        "review_rate": 5.0,
        "customer_name": "Teresa Rowland",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Courtney H.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.8,
        "customer_name": "Anthony Rose",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Brian L.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.8,
        "customer_name": "Jamie Jacobs",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Paul T.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Carolyn Davis",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Richard M.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Melissa Morris MD",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Darrell D.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Sherry Mata",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jennifer M.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Krystal Turner",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Justin D.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Robert Rubio",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Douglas D.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.9,
        "customer_name": "Daniel Davidson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jeremy J.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-23",
        "review_rate": 5.0,
        "customer_name": "Zachary Holt",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Timothy D.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-22",
        "review_rate": 5.0,
        "customer_name": "Kelly Cole",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kristen W.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-07",
        "review_rate": 5.0,
        "customer_name": "Darren Ramirez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Victoria R.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-18",
        "review_rate": 5.0,
        "customer_name": "Benjamin Johnson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Sonya A.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.8,
        "customer_name": "Charles Jones",
        "service_type": "Garage Door Opener",
        "tech_name": "Kelly G.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-19",
        "review_rate": 5.0,
        "customer_name": "Martha Sexton",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michael W.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Charles Simmons",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Joseph K.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "Destiny Reyes",
        "service_type": "Garage Door Services",
        "tech_name": "Darin H.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Lori Thompson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jesse B.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-29",
        "review_rate": 5.0,
        "customer_name": "James Fry",
        "service_type": "Garage Door Insulation",
        "tech_name": "Timothy H.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-02",
        "review_rate": 5.0,
        "customer_name": "Tina Carrillo",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Steven M.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.8,
        "customer_name": "Sean Duran",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Ronald P.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-31",
        "review_rate": 5.0,
        "customer_name": "Dominique Byrd",
        "service_type": "Garage Door Services",
        "tech_name": "Angela S.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.9,
        "customer_name": "Phyllis Hall",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Michael M.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Chelsey Andrade",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Pamela C.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Bobby Anderson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Catherine R.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.8,
        "customer_name": "Jennifer Salazar",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "John S.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Travis Townsend",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Erin S.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Connie Butler",
        "service_type": "Garage Door Opener",
        "tech_name": "Jose O.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Benjamin Oliver",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Lori Y.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "Christopher Knox",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Teresa D.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Patricia Cox",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Ryan D.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.8,
        "customer_name": "Lori Bullock",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Brett H.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Timothy Sutton",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Cameron N.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.8,
        "customer_name": "Marisa Rodriguez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Matthew M.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-17",
        "review_rate": 5.0,
        "customer_name": "Jacqueline Turner",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Dustin C.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-26",
        "review_rate": 5.0,
        "customer_name": "Becky Griffin",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Dawn B.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.8,
        "customer_name": "Eduardo Grimes MD",
        "service_type": "Garage Door Off Track",
        "tech_name": "Cheyenne K.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.8,
        "customer_name": "John Hall",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Joshua S.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.8,
        "customer_name": "Bradley Newman",
        "service_type": "Garage Door Opener",
        "tech_name": "Austin W.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Earl Hall",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Natalie H.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Sean Church",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Dawn T.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Laura Reyes",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "George W.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Sierra Robinson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Rachel M.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-09",
        "review_rate": 5.0,
        "customer_name": "Amber Thomas",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Henry L.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Small",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Scott O.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Natalie Rivera",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "John D.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Nathan Reyes",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jessica R.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.8,
        "customer_name": "Christine Kim MD",
        "service_type": "Garage Door Off Track",
        "tech_name": "Stephen P.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Thomas Luna",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Adam B.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Edward Keller",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "James H.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Gregory Lane",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Elizabeth S.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Arthur Turner",
        "service_type": "Garage Door Off Track",
        "tech_name": "Colton S.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Sean Holmes",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Shawn P.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Matthew Evans",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Shannon R.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-21",
        "review_rate": 5.0,
        "customer_name": "Michael Turner",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Alyssa J.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "Rachel Diaz",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Deborah S.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.8,
        "customer_name": "Debra Rodgers",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Devon M.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.8,
        "customer_name": "Angel Hayes",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jennifer W.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Justin Gallagher",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Nicholas J.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-01",
        "review_rate": 5.0,
        "customer_name": "Gregory Harper",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Christopher B.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-15",
        "review_rate": 5.0,
        "customer_name": "Olivia Lester",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Lisa B.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.8,
        "customer_name": "Kelly Mcpherson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jessica A.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Alyssa Flores",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Victoria G.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-29",
        "review_rate": 5.0,
        "customer_name": "Joseph Stewart",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Daniel S.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Tiffany Mahoney",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Brandon H.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.8,
        "customer_name": "David Ortiz",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kyle G.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-29",
        "review_rate": 5.0,
        "customer_name": "Brandon Jenkins",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Larry H.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.8,
        "customer_name": "Kelly Smith",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Theresa L.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Kyle Warren",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Michael H.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Thomas Thornton",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Shelly B.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Shannon Copeland",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kristy G.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-15",
        "review_rate": 5.0,
        "customer_name": "Edward Osborn",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Nancy G.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.8,
        "customer_name": "William Howell",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Amber S.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-16",
        "review_rate": 5.0,
        "customer_name": "Robert Hernandez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Maria S.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-31",
        "review_rate": 5.0,
        "customer_name": "Crystal White",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Beth W.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Alvin Berry",
        "service_type": "Custom Garage Door Design",
        "tech_name": "James P.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.8,
        "customer_name": "Troy Gomez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Darlene T.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-28",
        "review_rate": 5.0,
        "customer_name": "Douglas Wright",
        "service_type": "Garage Door Repair",
        "tech_name": "Becky J.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Matthew Baxter",
        "service_type": "Garage Door Repair",
        "tech_name": "Joseph H.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.8,
        "customer_name": "Kelly Yang",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Amber L.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-07",
        "review_rate": 5.0,
        "customer_name": "Christina Barron",
        "service_type": "Garage Door Services",
        "tech_name": "Robert Q.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Yvonne Tate",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "William R.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.8,
        "customer_name": "Felicia Carter",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Brandy P.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-31",
        "review_rate": 5.0,
        "customer_name": "Melissa Gomez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Roger F.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "Kristin Weaver",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Richard G.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-16",
        "review_rate": 5.0,
        "customer_name": "Stephanie Holt",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jesus T.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Phillip Weaver",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Olivia M.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.9,
        "customer_name": "Christopher Osborn",
        "service_type": "Garage Door Opener",
        "tech_name": "Melvin M.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.8,
        "customer_name": "Ryan David",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Alejandro C.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.8,
        "customer_name": "Leslie Ross",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jonathan F.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-10",
        "review_rate": 5.0,
        "customer_name": "Alyssa Harris",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Ronald S.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.8,
        "customer_name": "Chad Johnson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Timothy W.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.8,
        "customer_name": "Evan Jackson",
        "service_type": "Garage Door Opener",
        "tech_name": "Andrea E.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-12",
        "review_rate": 5.0,
        "customer_name": "Monica Smith",
        "service_type": "Garage Door Opener",
        "tech_name": "Jonathan M.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.8,
        "customer_name": "Joseph Cooper",
        "service_type": "Garage Door Repair",
        "tech_name": "Kenneth G.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.8,
        "customer_name": "Samantha Hernandez",
        "service_type": "Garage Door Repair",
        "tech_name": "Gerald M.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.9,
        "customer_name": "Garrett Delgado",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Linda M.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Angela Parks",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Olivia S.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Kathryn Anderson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Molly B.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Aaron Mendoza",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Samantha S.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.9,
        "customer_name": "Nicolas Murray",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Hannah H.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "Amber Cervantes",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Michael S.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Derek Robertson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Carol W.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.8,
        "customer_name": "Lisa Wallace",
        "service_type": "Garage Door Repair",
        "tech_name": "Derek S.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Kathryn Lee",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Sarah J.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.8,
        "customer_name": "John Walker",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Mikayla M.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Logan Gregory",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jose H.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Christina Aguilar",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jessica M.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-06",
        "review_rate": 5.0,
        "customer_name": "Carol Smith",
        "service_type": "Garage Door Off Track",
        "tech_name": "Nancy A.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Amber Perez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Carrie C.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.8,
        "customer_name": "Mary Ramos",
        "service_type": "Garage Door Opener",
        "tech_name": "Scott P.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.8,
        "customer_name": "Kevin White",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Mark M.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-18",
        "review_rate": 5.0,
        "customer_name": "Thomas Taylor",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christina B.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.8,
        "customer_name": "Cathy Monroe",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Steven W.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.8,
        "customer_name": "Edward Parrish",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Robert E.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Calvin Acosta",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "William D.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-09-29",
        "review_rate": 5.0,
        "customer_name": "Brian Brown",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Sandra R.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.8,
        "customer_name": "Alexander Sanchez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Max L.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-31",
        "review_rate": 5.0,
        "customer_name": "Deborah Scott",
        "service_type": "Garage Door Insulation",
        "tech_name": "Anthony S.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.9,
        "customer_name": "Tyler Benson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Shannon R.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-23",
        "review_rate": 5.0,
        "customer_name": "Kimberly Barron",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Laura J.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-23",
        "review_rate": 5.0,
        "customer_name": "Aaron Jensen",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jeffrey R.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Donna Cervantes",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Joseph M.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Brandon Cohen",
        "service_type": "Garage Door Off Track",
        "tech_name": "John K.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.8,
        "customer_name": "Herbert Bullock",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Patty B.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.9,
        "customer_name": "Benjamin Townsend",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Erika L.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.8,
        "customer_name": "James Baker",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Karen S.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-09",
        "review_rate": 5.0,
        "customer_name": "Tiffany Campbell",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kristopher O.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Angel Williams",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jennifer F.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.8,
        "customer_name": "Evan Elliott DDS",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Adriana R.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.8,
        "customer_name": "Jason Jones",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Gabriel G.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.8,
        "customer_name": "John Jones",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Mark R.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-15",
        "review_rate": 5.0,
        "customer_name": "Bryan Wagner",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jennifer H.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-21",
        "review_rate": 5.0,
        "customer_name": "Michelle Schneider",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "John B.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Rebecca Johnson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Tony F.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.8,
        "customer_name": "Cathy Harper",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Micheal D.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Amy Shaffer",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Adam D.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Gregory Pierce",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Katherine R.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Gregory Garcia",
        "service_type": "Garage Door Services",
        "tech_name": "Dylan B.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-17",
        "review_rate": 5.0,
        "customer_name": "Benjamin Richardson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Nicholas A.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-31",
        "review_rate": 5.0,
        "customer_name": "Heather Rose",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Andrew J.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-15",
        "review_rate": 5.0,
        "customer_name": "Alicia Berry",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Scott R.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Stefanie Chambers",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Christina J.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.8,
        "customer_name": "Christopher Hanson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Carrie C.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "Jacob Riley",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kara C.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-05",
        "review_rate": 5.0,
        "customer_name": "Chelsea Simpson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Deborah T.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.8,
        "customer_name": "Jonathan Pineda",
        "service_type": "Garage Door Off Track",
        "tech_name": "Benjamin M.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Hendricks",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Debra D.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Christine Austin",
        "service_type": "Garage Door Installation",
        "tech_name": "Joshua W.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-29",
        "review_rate": 5.0,
        "customer_name": "Clayton Cruz",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Samuel M.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.9,
        "customer_name": "Jesus Gilbert",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jessica M.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-23",
        "review_rate": 5.0,
        "customer_name": "Dr. Robert Smith",
        "service_type": "Garage Door Insulation",
        "tech_name": "Patricia A.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.8,
        "customer_name": "Timothy Nelson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jessica R.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-17",
        "review_rate": 5.0,
        "customer_name": "Timothy Johnson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jason G.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Sherry Curry",
        "service_type": "Garage Door Off Track",
        "tech_name": "Alejandro E.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-12",
        "review_rate": 5.0,
        "customer_name": "Clifford Baker",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Manuel S.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.8,
        "customer_name": "Tyler Reeves",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Amanda C.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.8,
        "customer_name": "Austin Hall",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Monica N.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-12",
        "review_rate": 5.0,
        "customer_name": "Anthony Carlson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jason G.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.8,
        "customer_name": "Kimberly Weeks",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Matthew G.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Carol Mccarthy",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Gina S.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "John Wilson II",
        "service_type": "Garage Door Opener",
        "tech_name": "Paige Y.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.8,
        "customer_name": "Robert Vazquez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Janice D.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-27",
        "review_rate": 5.0,
        "customer_name": "Miss Roberta Francis",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Michael Y.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "David Acevedo",
        "service_type": "Custom Garage Door Design",
        "tech_name": "James W.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Alexis Diaz",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Rebecca L.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Sarah Burgess",
        "service_type": "Garage Door Off Track",
        "tech_name": "Matthew W.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.8,
        "customer_name": "Jennifer Logan",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Robert T.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-12",
        "review_rate": 5.0,
        "customer_name": "Karen Mcdaniel",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Allen F.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Russell Kirby",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Thomas G.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-29",
        "review_rate": 5.0,
        "customer_name": "Danielle Mclean",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Brianna B.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Scott Chen",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Christopher M.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "James Smith",
        "service_type": "Garage Door Off Track",
        "tech_name": "Crystal G.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Kelly Kelley",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Lynn F.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "Michael Brewer",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jared L.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "Tyler Logan",
        "service_type": "Garage Door Insulation",
        "tech_name": "Wanda M.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-15",
        "review_rate": 5.0,
        "customer_name": "Jane Cain",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Michael T.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-20",
        "review_rate": 5.0,
        "customer_name": "Edward Harrison",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Michael J.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Becky Johnson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Julia C.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Destiny Mcmillan",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Ryan P.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.8,
        "customer_name": "Jennifer Cox",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Emily S.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.8,
        "customer_name": "Mrs. Janet Sanders DVM",
        "service_type": "Garage Door Insulation",
        "tech_name": "Phillip L.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Ashley Thomas",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Shannon B.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "Sharon Reed",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Stephanie D.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Benjamin Hutchinson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jessica B.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.8,
        "customer_name": "Jodi White",
        "service_type": "Garage Door Services",
        "tech_name": "Tammy T.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-15",
        "review_rate": 5.0,
        "customer_name": "Michele Wilkerson",
        "service_type": "Garage Door Opener",
        "tech_name": "Melissa H.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Richard Wells",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Shaun R.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Amy Myers",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kevin M.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.9,
        "customer_name": "Lonnie Smith",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Eric K.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Christine Cunningham",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Dylan P.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Joseph Reed",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Joseph F.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.9,
        "customer_name": "Kristina Walker",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Matthew R.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.8,
        "customer_name": "Mr. Matthew Santos",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Mia W.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Laura Ochoa",
        "service_type": "Garage Door Installation",
        "tech_name": "Danielle W.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.8,
        "customer_name": "Patricia Wilson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kelsey M.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Erica Simmons",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Brian M.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-04",
        "review_rate": 5.0,
        "customer_name": "Raymond Nguyen",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Brandon H.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-10",
        "review_rate": 5.0,
        "customer_name": "Daniel Williams",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Cassandra L.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Peggy Nelson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Sheri J.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-04",
        "review_rate": 5.0,
        "customer_name": "Harold Moore",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Patricia R.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "Jean Coffey",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Taylor N.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Michael Jones",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kyle C.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.8,
        "customer_name": "Misty George",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Carol N.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Alicia Morris",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Dalton M.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.9,
        "customer_name": "Dustin Duran",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jason F.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Steven Howell",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Arthur S.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-31",
        "review_rate": 5.0,
        "customer_name": "Gary Martinez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Emily W.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Tyrone Rodgers",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Joshua R.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Jeffery Wheeler",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Paul C.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-10",
        "review_rate": 5.0,
        "customer_name": "Kathryn Whitney",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Mark R.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Monica Ryan MD",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Russell G.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.8,
        "customer_name": "Steven Marshall",
        "service_type": "Garage Door Installation",
        "tech_name": "Marco R.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-16",
        "review_rate": 5.0,
        "customer_name": "Garrett King",
        "service_type": "Garage Door Track Repair",
        "tech_name": "James O.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Donna Waters",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Theodore W.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-28",
        "review_rate": 5.0,
        "customer_name": "Richard Moreno",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Eugene H.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Alexandra Munoz",
        "service_type": "Garage Door Off Track",
        "tech_name": "Christine R.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-27",
        "review_rate": 5.0,
        "customer_name": "Annette Dyer",
        "service_type": "Garage Door Off Track",
        "tech_name": "Michael C.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "William Odom",
        "service_type": "Garage Door Services",
        "tech_name": "Phillip M.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-19",
        "review_rate": 5.0,
        "customer_name": "Jessica Peterson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Richard R.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Taylor Hoover",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Joseph M.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.8,
        "customer_name": "Cheryl Martinez",
        "service_type": "Garage Door Opener",
        "tech_name": "Deanna T.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-30",
        "review_rate": 5.0,
        "customer_name": "Joshua Jefferson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Diane P.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-20",
        "review_rate": 5.0,
        "customer_name": "Melissa Miller",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kenneth D.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Sheila Ryan",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Wayne L.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Lisa White",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Ariel L.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-27",
        "review_rate": 5.0,
        "customer_name": "Frederick Conley",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Daniel C.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.8,
        "customer_name": "Jill Porter",
        "service_type": "Garage Door Off Track",
        "tech_name": "John V.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "Darren Erickson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kelly M.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Erik Avila",
        "service_type": "Garage Door Services",
        "tech_name": "Patty H.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Tyler Campos",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kayla J.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-09",
        "review_rate": 5.0,
        "customer_name": "Robert Porter",
        "service_type": "Garage Door Services",
        "tech_name": "Tonya P.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.8,
        "customer_name": "Sophia Ramirez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "James W.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.9,
        "customer_name": "Eric Torres",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Leslie L.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Jasmine Rogers",
        "service_type": "Garage Door Services",
        "tech_name": "Mary P.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Linda Roman",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "William G.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-17",
        "review_rate": 5.0,
        "customer_name": "Madison Willis",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Diana H.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Patricia Harper",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Justin B.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-01",
        "review_rate": 5.0,
        "customer_name": "Barry Smith",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Diana M.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.8,
        "customer_name": "Matthew Henderson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Richard C.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Darryl Adams PhD",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Corey M.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Rebecca Brady",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Vanessa D.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Sonya Nicholson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Justin R.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.8,
        "customer_name": "Amy Sanchez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Dustin G.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Benjamin Lang",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kelly B.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Christine Becker",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "David M.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-04",
        "review_rate": 5.0,
        "customer_name": "Nicholas Butler",
        "service_type": "Garage Door Insulation",
        "tech_name": "Patricia H.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Steve Smith",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Samuel G.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Larry Adams",
        "service_type": "Garage Door Repair",
        "tech_name": "David F.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Robert Smith",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Gail D.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Wendy Castillo",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Carla G.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Marvin Lucero",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Tami G.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Anthony Mclean",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kenneth H.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-23",
        "review_rate": 5.0,
        "customer_name": "Cheryl Summers",
        "service_type": "Garage Door Insulation",
        "tech_name": "Joel B.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-05",
        "review_rate": 5.0,
        "customer_name": "John Bates",
        "service_type": "Garage Door Off Track",
        "tech_name": "Matthew R.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Tina Patrick",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kimberly J.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Jacob Oneal PhD",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Nicholas S.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Bradley",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Rhonda D.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "William Allen",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Lisa K.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.8,
        "customer_name": "Kristopher Robles",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Peter G.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Jerome Castaneda",
        "service_type": "Garage Door Services",
        "tech_name": "Javier J.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-25",
        "review_rate": 5.0,
        "customer_name": "Brandy Thompson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Lisa F.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Robert Dunn",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jeffrey B.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Bruce Franco",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Alejandro M.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.9,
        "customer_name": "Katrina Smith",
        "service_type": "Garage Door Opener",
        "tech_name": "Wesley E.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-05",
        "review_rate": 5.0,
        "customer_name": "Christina Horn",
        "service_type": "Garage Door Services",
        "tech_name": "Sylvia C.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Alexander Hill",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Craig C.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.8,
        "customer_name": "Jonathan Middleton",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kayla C.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-17",
        "review_rate": 5.0,
        "customer_name": "Dr. Emily Christensen",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Geoffrey T.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-05",
        "review_rate": 5.0,
        "customer_name": "Eric Washington",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Megan G.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "Charles Trevino",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Candace N.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-01",
        "review_rate": 5.0,
        "customer_name": "Jeremy Cross PhD",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Keith M.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-02",
        "review_rate": 5.0,
        "customer_name": "Evan Ramirez",
        "service_type": "Garage Door Repair",
        "tech_name": "Lee D.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.8,
        "customer_name": "Amber Tanner",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "James O.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Tara Roth",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Lee R.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Laurie Smith",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Bruce F.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.8,
        "customer_name": "Felicia Herrera",
        "service_type": "Garage Door Repair",
        "tech_name": "Matthew A.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.9,
        "customer_name": "Todd Cruz",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Monique B.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.9,
        "customer_name": "Amanda Carroll",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Brandon G.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Robert Martin",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Dawn M.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.8,
        "customer_name": "Sonya Vasquez",
        "service_type": "Garage Door Opener",
        "tech_name": "Elizabeth M.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.8,
        "customer_name": "Sharon Stone PhD",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kevin T.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Michelle Smith",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Edward J.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.8,
        "customer_name": "Holly Smith",
        "service_type": "Garage Door Opener",
        "tech_name": "Robert T.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Mrs. Kaitlin Malone",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Frank B.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-07",
        "review_rate": 5.0,
        "customer_name": "Derrick Walsh",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jonathan C.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-07",
        "review_rate": 5.0,
        "customer_name": "Samuel Peterson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Gregory B.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.8,
        "customer_name": "Christopher Thompson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Natasha M.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.8,
        "customer_name": "Jacqueline Taylor",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kimberly W.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-24",
        "review_rate": 5.0,
        "customer_name": "Angel Kennedy",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Janet J.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Michael Alvarez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Lisa R.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Benjamin Peterson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Travis G.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-01",
        "review_rate": 5.0,
        "customer_name": "Angela Wright",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "James R.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "Michelle Glover",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Justin L.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-27",
        "review_rate": 5.0,
        "customer_name": "Jose Perez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Michael R.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Edwin Glover",
        "service_type": "Garage Door Off Track",
        "tech_name": "Nicole D.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "John Davis",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kathy H.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Cheryl Velasquez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Logan N.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.8,
        "customer_name": "Jessica Adams",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Tanya W.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-01",
        "review_rate": 5.0,
        "customer_name": "Miss Nicole Carlson DDS",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "John E.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Natalie Robinson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "John O.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "Christopher Martin",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Ashley M.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Tina Turner",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Daniel P.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-14",
        "review_rate": 5.0,
        "customer_name": "Jennifer Newton",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Mark H.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Lori Nicholson",
        "service_type": "Garage Door Services",
        "tech_name": "William B.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Kara Lopez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Miguel B.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Ashley Lindsey",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Alexander B.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "Gabrielle Frank",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jessica B.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Patricia Thomas",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Gregory G.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Karen Ramirez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Mariah J.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Thomas Nelson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Carl B.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.8,
        "customer_name": "Andrea Valencia",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Carol V.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Stefanie Knight",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Nicholas W.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-24",
        "review_rate": 5.0,
        "customer_name": "Jessica Smith",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kathy D.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Pamela Rogers",
        "service_type": "Garage Door Opener",
        "tech_name": "Justin H.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-23",
        "review_rate": 5.0,
        "customer_name": "Christine Thomas",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Eric R.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "Gabriel Lopez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Amanda M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-28",
        "review_rate": 5.0,
        "customer_name": "Tina Miller",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Valerie P.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Heidi Shields",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "James J.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-28",
        "review_rate": 5.0,
        "customer_name": "Nicholas Lam",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Diana S.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Veronica Black",
        "service_type": "Garage Door Insulation",
        "tech_name": "Robert M.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Amanda Harmon",
        "service_type": "Garage Door Repair",
        "tech_name": "Adam K.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Christian Griffin",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Caleb N.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Robert Fletcher",
        "service_type": "Residential Garage Door Services",
        "tech_name": "David C.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-01",
        "review_rate": 5.0,
        "customer_name": "Lisa Malone",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Andrew W.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.8,
        "customer_name": "Justin Schneider",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Pamela C.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Michael Blair",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Andrea O.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.8,
        "customer_name": "Russell Patel",
        "service_type": "Garage Door Services",
        "tech_name": "Connie W.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.8,
        "customer_name": "Christopher Allen",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Zachary M.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Brian Young",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Ashley S.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-27",
        "review_rate": 5.0,
        "customer_name": "Timothy Joseph",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jack B.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.8,
        "customer_name": "Harold Scott",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Julie V.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Daniel Lee",
        "service_type": "Garage Door Services",
        "tech_name": "Christopher F.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Hess",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jessica G.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Kevin Morrow",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Ethan S.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Daisy Mullins",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Aaron H.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Brian Mccall",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "James B.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-16",
        "review_rate": 5.0,
        "customer_name": "Tracy Cox",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Matthew C.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Bonnie Ashley",
        "service_type": "Garage Door Off Track",
        "tech_name": "David C.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "Lindsey Carter",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kimberly J.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-27",
        "review_rate": 5.0,
        "customer_name": "Christina Johnson DDS",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Tiffany O.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Kenneth Jacobson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Karen H.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Regina Le",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Brandon C.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.8,
        "customer_name": "Kent Mccarty",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Nathan J.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Autumn Garcia",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Joseph A.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Tonya Campbell",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Matthew S.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-24",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Marsh",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Katie G.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-21",
        "review_rate": 5.0,
        "customer_name": "Dr. Elaine Oconnell",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Adam Y.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.8,
        "customer_name": "Cindy Woodard",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Teresa C.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "James Schmidt",
        "service_type": "Garage Door Opener",
        "tech_name": "William M.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Catherine Harris",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Andrew F.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "David Brown",
        "service_type": "Garage Door Insulation",
        "tech_name": "Victoria C.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-05",
        "review_rate": 5.0,
        "customer_name": "Tammy Horton",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jose S.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.8,
        "customer_name": "Michael Davis",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "David F.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Joseph Bradley",
        "service_type": "Garage Door Off Track",
        "tech_name": "Steven P.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Jorge Barnes",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jonathan E.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-08",
        "review_rate": 5.0,
        "customer_name": "Ann Weber",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "William W.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.8,
        "customer_name": "Michael Clark",
        "service_type": "Residential Garage Door Services",
        "tech_name": "William C.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Edward Williams",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Andrew D.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.9,
        "customer_name": "Rebekah Price",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Karen M.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.8,
        "customer_name": "Kristen Smith",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Christopher C.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Christopher Chavez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Michele K.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.8,
        "customer_name": "Mario Durham",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Timothy G.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Margaret Robles",
        "service_type": "Garage Door Insulation",
        "tech_name": "Laurie R.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.8,
        "customer_name": "Michelle Mata",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Joseph F.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Gail Stone",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Alyssa F.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.8,
        "customer_name": "Laura Murray",
        "service_type": "Garage Door Installation",
        "tech_name": "Shane M.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-30",
        "review_rate": 5.0,
        "customer_name": "Tiffany Hernandez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Michael T.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Justin Cannon",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Christopher R.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Misty Collins",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Joseph P.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Patrick Hernandez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Mary M.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.8,
        "customer_name": "Edward Mendez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Carrie P.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.8,
        "customer_name": "Susan Smith",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Heather M.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.8,
        "customer_name": "Angela Kaiser",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Mark C.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Katie Little",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Priscilla B.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Jennifer Cochran",
        "service_type": "Garage Door Insulation",
        "tech_name": "Scott F.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Daniel Smith",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Carl W.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-03",
        "review_rate": 5.0,
        "customer_name": "Cody Miller",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kendra H.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.8,
        "customer_name": "Tammy Palmer",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Heather M.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.8,
        "customer_name": "Patrick Barrett",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Amber G.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "Joshua Mayo",
        "service_type": "Garage Door Insulation",
        "tech_name": "Ashley J.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-26",
        "review_rate": 5.0,
        "customer_name": "Melissa Edwards",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Angela W.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Michelle Campbell",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Amber J.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Kenneth Carter",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Michelle W.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Craig Herrera",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Courtney A.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.8,
        "customer_name": "Paul Day",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Anthony K.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-05",
        "review_rate": 5.0,
        "customer_name": "Mary Anderson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Peter W.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-23",
        "review_rate": 5.0,
        "customer_name": "James Meyer",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Danny R.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-15",
        "review_rate": 5.0,
        "customer_name": "Amanda Wagner",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kristina D.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.8,
        "customer_name": "Andrew Ibarra",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Lisa P.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.8,
        "customer_name": "Jennifer Jordan",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Heather B.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Veronica Brown",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "John P.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.8,
        "customer_name": "Sarah Bush",
        "service_type": "Garage Door Repair",
        "tech_name": "Jerry V.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Barbara Moore",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Paige H.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Emma Miller",
        "service_type": "Garage Door Installation",
        "tech_name": "Jennifer B.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-25",
        "review_rate": 5.0,
        "customer_name": "Brenda Mayo",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Brandon S.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Stephen Nguyen",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Melanie S.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Julie Smith",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Heather F.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Edward Harris",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Christopher J.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-16",
        "review_rate": 5.0,
        "customer_name": "Anthony Williamson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Carol S.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-14",
        "review_rate": 5.0,
        "customer_name": "Deborah Andrews",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Isaac F.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Jeremy Cobb",
        "service_type": "Garage Door Off Track",
        "tech_name": "Sara R.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.9,
        "customer_name": "Nancy Flynn",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Nathan F.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.8,
        "customer_name": "Matthew Bell",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Sharon G.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-16",
        "review_rate": 5.0,
        "customer_name": "John Walls",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "John R.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-29",
        "review_rate": 5.0,
        "customer_name": "Daniel Morris",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Amanda S.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Kristina Knox",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Christine S.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.8,
        "customer_name": "Benjamin Cobb",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Duane D.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Matthew Galvan",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "William M.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-26",
        "review_rate": 5.0,
        "customer_name": "Laura Fernandez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Laura J.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.8,
        "customer_name": "Joel Pineda",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Anthony R.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Mackenzie Romero",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Lori M.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-20",
        "review_rate": 5.0,
        "customer_name": "Jessica Christensen",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Timothy J.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-16",
        "review_rate": 5.0,
        "customer_name": "Jamie Smith",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Crystal W.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "Brianna Davies",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jamie M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Randy Miller",
        "service_type": "Garage Door Insulation",
        "tech_name": "Megan T.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-14",
        "review_rate": 5.0,
        "customer_name": "Thomas Smith",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Gregory D.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-17",
        "review_rate": 5.0,
        "customer_name": "Andrew Smith Jr.",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Brian A.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Heidi Blackwell",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Ralph J.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.9,
        "customer_name": "Lisa Cole",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Anna W.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Lisa Arnold",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jason W.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Danielle Higgins",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Chelsea A.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-07",
        "review_rate": 5.0,
        "customer_name": "Brittany Reyes",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Eric T.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-08",
        "review_rate": 5.0,
        "customer_name": "Hannah Espinoza",
        "service_type": "Garage Door Installation",
        "tech_name": "Alvin W.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.8,
        "customer_name": "Brian Johnson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "David M.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Scott Calderon",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Lisa W.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.8,
        "customer_name": "Jennifer Smith",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Elizabeth W.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.8,
        "customer_name": "Regina Hill",
        "service_type": "Garage Door Repair",
        "tech_name": "Brett F.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "Shane Brown",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Paul K.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "James Blanchard",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Karen R.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-13",
        "review_rate": 5.0,
        "customer_name": "Kayla Mckee",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Christopher F.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-21",
        "review_rate": 5.0,
        "customer_name": "Justin Charles",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Karen W.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Michelle Daniels",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jillian S.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Shawn Miller",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Paul W.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-05",
        "review_rate": 5.0,
        "customer_name": "Alexa Walters",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Tyler L.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "James Miller",
        "service_type": "Garage Door Installation",
        "tech_name": "Emily C.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Andrea Hall",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Andrew F.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.8,
        "customer_name": "Michelle Mills",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Laura R.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-22",
        "review_rate": 5.0,
        "customer_name": "Jessica Smith",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Carol S.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.8,
        "customer_name": "Rachel Benton",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Sierra R.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Roy Smith PhD",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jacob F.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "Michael Tucker",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Donald B.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.9,
        "customer_name": "Kelsey Powell MD",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kimberly B.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "Gabriel Adams",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Crystal J.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Steven Bowman",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Joshua D.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.8,
        "customer_name": "Nicholas Jackson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Tamara C.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-10",
        "review_rate": 5.0,
        "customer_name": "James Church",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Daniel Y.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.8,
        "customer_name": "Katie Campbell",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Ricky P.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.8,
        "customer_name": "Matthew Berry",
        "service_type": "Garage Door Services",
        "tech_name": "Robert C.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Felicia Best",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Keith B.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.8,
        "customer_name": "Michelle May",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Oscar W.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-14",
        "review_rate": 5.0,
        "customer_name": "Joanna Carpenter",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Susan C.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-18",
        "review_rate": 5.0,
        "customer_name": "Jamie Wilson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christina K.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-03",
        "review_rate": 5.0,
        "customer_name": "Thomas Love",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jacqueline T.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Elaine Clark",
        "service_type": "Garage Door Opener",
        "tech_name": "Robert F.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-16",
        "review_rate": 5.0,
        "customer_name": "Scott Herring",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Shari N.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Jonathan Morrow",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "David S.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Robert Fields",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Danielle J.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "Lori Hansen",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Brian P.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.8,
        "customer_name": "Kenneth Knight",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Tonya S.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.8,
        "customer_name": "Robert Rodriguez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Gregory B.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Julie Sims",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Julie R.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-23",
        "review_rate": 5.0,
        "customer_name": "Brianna Bauer",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Mark F.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Melissa Phillips",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Lorraine C.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Tina Garcia",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Paula C.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-28",
        "review_rate": 5.0,
        "customer_name": "Jacqueline Thompson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Christopher S.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Anthony Taylor",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Bryce C.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Dr. Jennifer Fritz DVM",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Cheryl B.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "Lonnie Davis",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Marissa M.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-09",
        "review_rate": 5.0,
        "customer_name": "Ryan Williams",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Crystal O.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Betty Rowe",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Stacey S.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.9,
        "customer_name": "Tonya Carpenter",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jennifer G.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.8,
        "customer_name": "William Lopez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "William C.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Christopher Bullock",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Hannah F.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.8,
        "customer_name": "Patricia Perez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Matthew A.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Mike Payne",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "William P.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Garrett Rivas",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Brian N.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Denise Lewis",
        "service_type": "Garage Door Installation",
        "tech_name": "Sarah J.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.8,
        "customer_name": "Taylor Bennett",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Elizabeth J.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-02",
        "review_rate": 5.0,
        "customer_name": "Thomas Collins",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Adam M.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "Mrs. Maria Hoffman",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jason J.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Michelle Johnson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Nicole C.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "Bruce Banks",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "John H.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "Rachel Robles",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kevin S.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "Stacey Jennings",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Michael B.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-08",
        "review_rate": 5.0,
        "customer_name": "Melissa Boyd",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Willie H.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Catherine Duarte",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Laurie T.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Nicole Evans",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Madeline R.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-10",
        "review_rate": 5.0,
        "customer_name": "Dawn Ritter",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Wendy L.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-17",
        "review_rate": 5.0,
        "customer_name": "Katrina Park",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Spencer B.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-09",
        "review_rate": 5.0,
        "customer_name": "Amy Daugherty",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Richard M.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.9,
        "customer_name": "Tracy Haas",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Lisa A.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Michelle Gates",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Tammy S.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.8,
        "customer_name": "Bradley Chambers",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Michelle H.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Kevin Kelly",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Amy Y.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Molly Brown",
        "service_type": "Garage Door Repair",
        "tech_name": "Brent A.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-09",
        "review_rate": 5.0,
        "customer_name": "Larry Smith",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Nicolas H.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Brian Mcclure",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Joshua H.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "Theodore Shea",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Joe P.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-18",
        "review_rate": 5.0,
        "customer_name": "Jasmine Watkins",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Dave M.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Jonathan Baldwin",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Laura W.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Robert Brady",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jean F.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Theresa Schwartz",
        "service_type": "Custom Garage Door Design",
        "tech_name": "John H.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.8,
        "customer_name": "Judy Goodwin",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Tracy J.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Carrillo",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Erika T.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.8,
        "customer_name": "Randy Reeves DVM",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Tina G.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Frederick Conley",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Deanna M.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-24",
        "review_rate": 5.0,
        "customer_name": "John Hayes",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kyle M.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Christopher Wilkerson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jonathan H.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Ashley Nguyen",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Bethany T.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-09-30",
        "review_rate": 5.0,
        "customer_name": "Lisa Townsend",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Angela S.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Matthew Lambert",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jennifer H.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.9,
        "customer_name": "Teresa Reynolds",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Marilyn D.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Alicia Baird",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Joan B.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Melinda Bell",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Christopher S.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Isaac Gonzalez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Michael P.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Raymond Romero",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "David G.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Ashley Martinez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Patrick C.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Melissa Thornton",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Richard C.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Danielle Roberts",
        "service_type": "Custom Garage Door Design",
        "tech_name": "George Z.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Nicholas Davis",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jeremy S.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-01",
        "review_rate": 5.0,
        "customer_name": "David Juarez",
        "service_type": "Garage Door Services",
        "tech_name": "Thomas P.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-18",
        "review_rate": 5.0,
        "customer_name": "Amy Cook",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michelle J.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Christopher English",
        "service_type": "Garage Door Off Track",
        "tech_name": "Lisa W.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Sara Choi",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kelly J.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-07",
        "review_rate": 5.0,
        "customer_name": "Matthew Walker",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jennifer M.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Vincent Wall",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Emily P.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-09",
        "review_rate": 5.0,
        "customer_name": "Omar Torres",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Lori S.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-09-29",
        "review_rate": 5.0,
        "customer_name": "Sarah Wells",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Alisha M.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-16",
        "review_rate": 5.0,
        "customer_name": "David Buchanan",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Trevor T.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-09-29",
        "review_rate": 5.0,
        "customer_name": "Douglas Long",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Melissa H.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.8,
        "customer_name": "David Hanna",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Matthew H.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Jasmine Lowery",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Steven G.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.8,
        "customer_name": "Christine Hernandez",
        "service_type": "Garage Door Opener",
        "tech_name": "Patricia W.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Steven Anderson",
        "service_type": "Garage Door Repair",
        "tech_name": "Jerry C.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.8,
        "customer_name": "John Sanchez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Amy C.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Beverly Walter",
        "service_type": "Garage Door Services",
        "tech_name": "Michael F.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.8,
        "customer_name": "Lori Smith",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jean B.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.8,
        "customer_name": "John Rodriguez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Mark M.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-08",
        "review_rate": 5.0,
        "customer_name": "Robert Nelson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "John H.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Katelyn Nicholson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Morgan F.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Brenda Newman",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Edward A.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Kyle Bennett",
        "service_type": "Garage Door Opener",
        "tech_name": "Michelle V.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Mary Lopez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jesus H.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-05",
        "review_rate": 5.0,
        "customer_name": "Jennifer Rodriguez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Anthony W.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Amanda Riley",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Judith J.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Jamie Daniel",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Joshua G.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.8,
        "customer_name": "Jonathon Walls",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Katherine D.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Ethan Young",
        "service_type": "Garage Door Installation",
        "tech_name": "Autumn W.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Charlotte Giles",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Mark G.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-09-29",
        "review_rate": 5.0,
        "customer_name": "Nicole Hartman",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Linda M.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.8,
        "customer_name": "Heather Duncan",
        "service_type": "Garage Door Services",
        "tech_name": "Melinda B.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.8,
        "customer_name": "Brian Pham",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Denise O.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "Angela Lee",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Hunter C.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-24",
        "review_rate": 5.0,
        "customer_name": "Patricia Davis",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Tyler A.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-13",
        "review_rate": 5.0,
        "customer_name": "Shelby Turner",
        "service_type": "Garage Door Installation",
        "tech_name": "Joel F.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.8,
        "customer_name": "Ashley Lewis",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Michelle W.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Caroline Tapia",
        "service_type": "Garage Door Opener",
        "tech_name": "Catherine J.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-20",
        "review_rate": 5.0,
        "customer_name": "Anna Mccoy",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Alex E.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.8,
        "customer_name": "Andrew Graham",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jacob J.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-19",
        "review_rate": 5.0,
        "customer_name": "Mary Solis",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jeffrey D.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Glenda Hall",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Aaron D.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Barbara Grant",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Scott R.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.9,
        "customer_name": "Megan Greer",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jennifer B.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-09-27",
        "review_rate": 5.0,
        "customer_name": "Amber Gregory",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Andrew H.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.9,
        "customer_name": "Brenda Morgan",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Priscilla B.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Keith Paul",
        "service_type": "Garage Door Insulation",
        "tech_name": "Karen W.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Bradford",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Ricardo H.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.8,
        "customer_name": "Amy Forbes",
        "service_type": "Garage Door Opener",
        "tech_name": "Cynthia R.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "John Howe",
        "service_type": "Garage Door Opener",
        "tech_name": "Caitlin T.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Francisco Jimenez",
        "service_type": "Garage Door Installation",
        "tech_name": "Alexandria A.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.8,
        "customer_name": "Pamela Braun",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Erica D.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-17",
        "review_rate": 5.0,
        "customer_name": "David Williams",
        "service_type": "Garage Door Insulation",
        "tech_name": "Robert L.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-19",
        "review_rate": 5.0,
        "customer_name": "Kim Curry",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Derek H.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.8,
        "customer_name": "Richard Haney",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Katrina H.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-19",
        "review_rate": 5.0,
        "customer_name": "Amber Peterson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Gregory Y.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-21",
        "review_rate": 5.0,
        "customer_name": "Zachary Barr",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Natalie R.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-05",
        "review_rate": 5.0,
        "customer_name": "Sylvia Hughes",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Patricia M.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Miss Pamela Stone",
        "service_type": "Garage Door Insulation",
        "tech_name": "Caitlin H.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-19",
        "review_rate": 5.0,
        "customer_name": "Jimmy Murphy",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Frances R.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-09",
        "review_rate": 5.0,
        "customer_name": "Thomas Aguirre",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Cynthia Y.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Paul Cobb",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Glenda D.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-04",
        "review_rate": 5.0,
        "customer_name": "Trevor Williams",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Tonya G.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Dawn Johnson",
        "service_type": "Garage Door Services",
        "tech_name": "Martin S.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.8,
        "customer_name": "George Cox",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kim B.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-03",
        "review_rate": 5.0,
        "customer_name": "Kenneth Peterson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jenna R.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Thomas Fitzgerald",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Roger G.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.8,
        "customer_name": "Christina Ramirez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jamie M.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-27",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Davis",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Brett W.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.8,
        "customer_name": "Dustin Parrish",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Tamara N.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Melissa Dunn",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Sarah A.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-21",
        "review_rate": 5.0,
        "customer_name": "Dr. Joshua Flores",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Charlotte B.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.8,
        "customer_name": "Kelly Harper",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Darryl N.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-11",
        "review_rate": 5.0,
        "customer_name": "Ronnie Leblanc",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Diana M.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-18",
        "review_rate": 5.0,
        "customer_name": "Laura Swanson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Denise G.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Allison Adams",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Sara N.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Sandra Steele",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Mary S.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.8,
        "customer_name": "Jennifer Wilson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Robert L.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "William Anderson",
        "service_type": "Garage Door Installation",
        "tech_name": "Michael M.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.9,
        "customer_name": "Timothy Wood",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Claudia M.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Tony Jensen",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Richard R.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Day",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Hannah L.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Tiffany Davenport",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jeff D.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "Alexander Robertson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Victor G.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-22",
        "review_rate": 5.0,
        "customer_name": "Barry James",
        "service_type": "Garage Door Installation",
        "tech_name": "Kara W.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-05",
        "review_rate": 5.0,
        "customer_name": "Bruce Murray",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jackie W.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Ashley Martinez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kevin J.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-31",
        "review_rate": 5.0,
        "customer_name": "Lisa Chen",
        "service_type": "Garage Door Opener",
        "tech_name": "Julie T.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.8,
        "customer_name": "Crystal Perkins",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Melissa K.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-03",
        "review_rate": 5.0,
        "customer_name": "Bianca Taylor",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Joshua L.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-18",
        "review_rate": 5.0,
        "customer_name": "Larry Scott",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Amy M.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-18",
        "review_rate": 5.0,
        "customer_name": "Daniel Taylor",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Daniel E.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Carolyn Ibarra",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Brian A.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.9,
        "customer_name": "Katherine Summers",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Rhonda B.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Sydney Clark",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Samantha B.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.8,
        "customer_name": "Todd Wade",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Leslie B.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Thomas Day",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jennifer C.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-14",
        "review_rate": 5.0,
        "customer_name": "Matthew Rocha",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Colleen J.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.8,
        "customer_name": "Eric Blair",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Cathy B.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Eric Williams",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Carrie B.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-12",
        "review_rate": 5.0,
        "customer_name": "Sylvia Dominguez",
        "service_type": "Garage Door Installation",
        "tech_name": "Patricia C.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Cindy Ross",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Anthony D.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Kerry Garza",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Christopher M.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-18",
        "review_rate": 5.0,
        "customer_name": "Todd Fleming",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Robert B.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Brian Warren Jr.",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kaitlin R.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.8,
        "customer_name": "Benjamin Mosley",
        "service_type": "Garage Door Opener",
        "tech_name": "Kelly W.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.8,
        "customer_name": "Andrea Baker",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Cynthia A.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Alicia Henderson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "David W.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Dylan Lawrence",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brenda E.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-27",
        "review_rate": 5.0,
        "customer_name": "Ellen Brown",
        "service_type": "Garage Door Opener",
        "tech_name": "Michael F.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Melanie Thompson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Amanda H.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.8,
        "customer_name": "Olivia Brown",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brittany W.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-28",
        "review_rate": 5.0,
        "customer_name": "Rebecca Perkins",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kimberly A.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.8,
        "customer_name": "Christopher Espinoza",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Stacie N.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-26",
        "review_rate": 5.0,
        "customer_name": "Ronald Li",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Deborah L.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-30",
        "review_rate": 5.0,
        "customer_name": "Dr. Scott Smith DDS",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Courtney P.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Thomas Williams",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Courtney L.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Randall Shields",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Christopher G.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.8,
        "customer_name": "Mary Ellis",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "David J.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.8,
        "customer_name": "Christine Smith",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Corey R.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Michael Woods",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Scott H.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-26",
        "review_rate": 5.0,
        "customer_name": "Allen Stone",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Eric J.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Jennifer Stout",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jon S.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-14",
        "review_rate": 5.0,
        "customer_name": "Timothy Taylor",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kayla C.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Krista Stout",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Melissa M.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Gary Jackson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Gina F.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Patricia Moon",
        "service_type": "Garage Door Services",
        "tech_name": "Lee B.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "Daisy Martin",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Tammy C.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "Veronica Jordan",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Amanda C.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Rick Schaefer",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Tara M.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Sarah Rosales",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Randy A.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-16",
        "review_rate": 5.0,
        "customer_name": "Frederick Powers",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Maurice V.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Mrs. Heather Tran",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Christopher L.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.8,
        "customer_name": "Donna Reyes",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Gavin W.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-15",
        "review_rate": 5.0,
        "customer_name": "Heather Smith",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Robin M.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.8,
        "customer_name": "Derek Bridges",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Charles M.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.9,
        "customer_name": "Kyle Brooks",
        "service_type": "Garage Door Insulation",
        "tech_name": "Adam B.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "Philip Jimenez",
        "service_type": "Garage Door Opener",
        "tech_name": "Vanessa F.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.8,
        "customer_name": "Brendan Nguyen",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Francisco J.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-12",
        "review_rate": 5.0,
        "customer_name": "Amy Mendoza",
        "service_type": "Garage Door Installation",
        "tech_name": "Todd C.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "Monica Smith",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michael S.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "Jeff Vargas",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Frank B.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Erin Gibson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Thomas O.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Melissa Hunt",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Derrick B.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.8,
        "customer_name": "Sean Roach",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Logan S.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "Shawn Rodriguez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Laurie R.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "John Henry",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Emily V.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Jonathan Rodriguez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Laurie W.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.9,
        "customer_name": "Michelle Campbell",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Amy R.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Garcia MD",
        "service_type": "Garage Door Services",
        "tech_name": "Larry W.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.8,
        "customer_name": "Crystal Morris",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Lauren D.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.8,
        "customer_name": "Kelly Gaines",
        "service_type": "Garage Door Track Repair",
        "tech_name": "David R.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-10",
        "review_rate": 5.0,
        "customer_name": "Nicholas Smith",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Debra B.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-02",
        "review_rate": 5.0,
        "customer_name": "Joseph Robinson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Marie L.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.8,
        "customer_name": "Ashley Mckenzie",
        "service_type": "Garage Door Opener",
        "tech_name": "Carl P.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-20",
        "review_rate": 5.0,
        "customer_name": "John Krause",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Ashley P.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Anthony Whitehead",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "William W.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-07",
        "review_rate": 5.0,
        "customer_name": "Joshua Cook",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Matthew G.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Dr. Katherine Gonzales",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Keith N.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Patricia Campbell",
        "service_type": "Garage Door Off Track",
        "tech_name": "Shawn K.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.8,
        "customer_name": "William Lynch",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Perry C.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.8,
        "customer_name": "Deborah Fisher",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Derek K.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Katherine Bailey",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Carl J.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Whitney Wolf",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kevin F.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.9,
        "customer_name": "Tanya Mendoza",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Lindsay B.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.9,
        "customer_name": "Michelle Mclaughlin",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Megan M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.8,
        "customer_name": "Anthony Stanley",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Howard H.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Paul Thomas",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "John W.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.9,
        "customer_name": "Robert Jackson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Daniel R.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.8,
        "customer_name": "James Allen",
        "service_type": "Garage Door Services",
        "tech_name": "Eric H.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-28",
        "review_rate": 5.0,
        "customer_name": "Megan Hogan",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Tara L.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Dr. Cynthia Giles",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Stephen S.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Michael Herrera",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Joseph G.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Erica Davis",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Anne H.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Harold Pena",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Rachel G.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-30",
        "review_rate": 5.0,
        "customer_name": "Karen Dyer",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Eddie A.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "Michael Smith",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Paul A.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "Erica Rivera",
        "service_type": "Garage Door Off Track",
        "tech_name": "Heather P.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-01",
        "review_rate": 5.0,
        "customer_name": "Jodi Davis",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jennifer A.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-15",
        "review_rate": 5.0,
        "customer_name": "Scott Smith",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Penny M.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-27",
        "review_rate": 5.0,
        "customer_name": "Cameron Henson",
        "service_type": "Garage Door Opener",
        "tech_name": "Jeanne M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Melissa Patrick",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Eric C.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-29",
        "review_rate": 5.0,
        "customer_name": "Michael Valencia",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Brett B.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Abigail Gutierrez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Paula M.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Diana Evans",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Donald W.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-06",
        "review_rate": 5.0,
        "customer_name": "Michaela West",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Philip J.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "Ashley Marshall",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Christopher H.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Denise Norris",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Robert S.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-03",
        "review_rate": 5.0,
        "customer_name": "Cheryl Cochran",
        "service_type": "Garage Door Opener",
        "tech_name": "Shane A.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-05",
        "review_rate": 5.0,
        "customer_name": "Sonya Horton",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Amber B.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-02",
        "review_rate": 5.0,
        "customer_name": "Crystal Cox",
        "service_type": "Garage Door Opener",
        "tech_name": "Anthony S.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Corey Logan",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Monica H.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.9,
        "customer_name": "Deborah Alvarado",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Robert H.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.8,
        "customer_name": "Tyler Foster",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Ashley N.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-28",
        "review_rate": 5.0,
        "customer_name": "Jennifer Berg",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Andrew D.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Gerald Snyder",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "April R.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.8,
        "customer_name": "Maria Walls",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jacob T.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-14",
        "review_rate": 5.0,
        "customer_name": "Andrew Clark",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Ann D.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.8,
        "customer_name": "Brian Lee",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Victor M.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-24",
        "review_rate": 5.0,
        "customer_name": "Russell Maldonado",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Victor C.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Shawn Wright",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Leah J.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Kevin Allison",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Chelsea R.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Savannah Harper",
        "service_type": "Garage Door Repair",
        "tech_name": "Sandy H.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.9,
        "customer_name": "Devin Lewis",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Edward R.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-15",
        "review_rate": 5.0,
        "customer_name": "Julie Gaines",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Cindy L.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.8,
        "customer_name": "Michael Andersen",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Marc H.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-14",
        "review_rate": 5.0,
        "customer_name": "Kristin Lopez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Daniel B.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Bradley Vincent",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Robert A.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.8,
        "customer_name": "Justin Sanchez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Michael W.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-08",
        "review_rate": 5.0,
        "customer_name": "Brian Gallagher",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Joshua A.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-22",
        "review_rate": 5.0,
        "customer_name": "Tammy Davis",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Brianna V.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.8,
        "customer_name": "Jose Mendoza MD",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Lisa M.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "Robert Garza",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Scott B.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Jose Stone",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Dana M.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-02",
        "review_rate": 5.0,
        "customer_name": "Robyn Richards",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Taylor T.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Marilyn Swanson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Emma V.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Kayla Smith",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Drew L.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "John Wong",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Heather S.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Thomas Morgan",
        "service_type": "Garage Door Services",
        "tech_name": "Michael L.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Cathy Guzman",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Richard A.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.8,
        "customer_name": "Nicole Garcia",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Thomas S.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-17",
        "review_rate": 5.0,
        "customer_name": "Haley Daugherty",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Catherine W.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.9,
        "customer_name": "David Moore",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Maria J.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.8,
        "customer_name": "Tina Young",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Matthew H.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Sean Cordova",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Alejandro F.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Joseph Cruz",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Richard W.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Tyler Nelson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Donald D.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Timothy Lin",
        "service_type": "Garage Door Installation",
        "tech_name": "Patrick E.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-15",
        "review_rate": 5.0,
        "customer_name": "Scott Kramer",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Elizabeth Z.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Michael Spears",
        "service_type": "Garage Door Off Track",
        "tech_name": "Linda R.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Benjamin Carter",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Vicki A.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Audrey Collins",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jeffrey W.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.8,
        "customer_name": "Jonathan Juarez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Steven J.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.8,
        "customer_name": "Bryan Case",
        "service_type": "Garage Door Repair",
        "tech_name": "Cassie B.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-14",
        "review_rate": 5.0,
        "customer_name": "Michael Thomas",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Matthew H.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-11",
        "review_rate": 5.0,
        "customer_name": "Jennifer Johnson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Melissa S.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Bradley White",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Cathy S.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.8,
        "customer_name": "Paul Sanchez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kevin L.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.8,
        "customer_name": "Jerome Torres",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Connie P.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Tara Johnson MD",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Allison S.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Taylor Glover",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kristen T.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.8,
        "customer_name": "James Johnson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Donna B.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.8,
        "customer_name": "Steven Gonzalez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Nichole H.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-22",
        "review_rate": 5.0,
        "customer_name": "Kristina Parker",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Taylor W.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Matthew Mendoza",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Nicholas H.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Joshua Baker",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Bryce B.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Barbara Weber",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Amanda H.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-30",
        "review_rate": 5.0,
        "customer_name": "Debbie Paul",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Tracy O.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-14",
        "review_rate": 5.0,
        "customer_name": "Morgan Jones",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Nicholas S.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Ryan Gregory",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jennifer G.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.8,
        "customer_name": "Daniel Baker",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Daniel F.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.8,
        "customer_name": "Danielle Bonilla",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Tamara M.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.8,
        "customer_name": "Gary Petty",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Brandon H.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.9,
        "customer_name": "Amanda Hall",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Christina J.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-22",
        "review_rate": 5.0,
        "customer_name": "Isaac Mack",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Susan P.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.8,
        "customer_name": "Brooke Grant",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jermaine C.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Alex Roberson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Anna R.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Robin Miller",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Joe T.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-14",
        "review_rate": 5.0,
        "customer_name": "Anthony Austin",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jeremy L.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Ashley Olson",
        "service_type": "Garage Door Repair",
        "tech_name": "Jorge L.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.8,
        "customer_name": "Vickie Coffey",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Shawn S.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-13",
        "review_rate": 5.0,
        "customer_name": "Casey Bell",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Brian W.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Howard Evans",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Blake L.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.8,
        "customer_name": "Javier Torres",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Aaron B.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Danielle Martin",
        "service_type": "Garage Door Off Track",
        "tech_name": "Danielle M.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-22",
        "review_rate": 5.0,
        "customer_name": "Rose Noble",
        "service_type": "Garage Door Opener",
        "tech_name": "Dawn B.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Sarah Cervantes",
        "service_type": "Garage Door Opener",
        "tech_name": "Melissa B.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-16",
        "review_rate": 5.0,
        "customer_name": "Alexis Shah",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Phillip H.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.9,
        "customer_name": "Joel Huber",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jeanette H.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-23",
        "review_rate": 5.0,
        "customer_name": "Elaine Robinson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Marissa W.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-18",
        "review_rate": 5.0,
        "customer_name": "Melissa Tate",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Thomas S.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "Kimberly Bryant",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Mary M.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.9,
        "customer_name": "Mark Johnson",
        "service_type": "Garage Door Repair",
        "tech_name": "Joshua L.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.8,
        "customer_name": "Corey Solomon",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Joseph R.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-10",
        "review_rate": 5.0,
        "customer_name": "Cody Marshall",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jessica M.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Gary Walter",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Troy T.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Joel Salinas",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Erica C.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.8,
        "customer_name": "Maureen Robinson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Derek M.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-06",
        "review_rate": 5.0,
        "customer_name": "Victoria Alexander",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Stacy G.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-06",
        "review_rate": 5.0,
        "customer_name": "Michael Burke",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Brian B.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-09",
        "review_rate": 5.0,
        "customer_name": "Samantha Chandler",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Vincent L.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Whitney James",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Brian O.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.8,
        "customer_name": "Jennifer Lamb",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Calvin T.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Melissa Wilson MD",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Douglas E.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Cindy Ross",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jeremy S.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.8,
        "customer_name": "Jason Johnson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Scott J.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.8,
        "customer_name": "Denise Porter",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Danielle G.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.8,
        "customer_name": "Anthony Mathis",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Maria S.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-03",
        "review_rate": 5.0,
        "customer_name": "Cory Thomas",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Richard L.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.8,
        "customer_name": "Scott Scott",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "James S.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-14",
        "review_rate": 5.0,
        "customer_name": "Peggy Dennis",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Mark J.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Victoria Petersen",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Donald W.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Matthew Snyder",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kevin M.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Abigail Howell",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Vincent Y.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Charles Summers",
        "service_type": "Garage Door Insulation",
        "tech_name": "Hannah M.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Eric Singh",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jessica J.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.8,
        "customer_name": "Scott Hansen",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Joseph M.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.8,
        "customer_name": "Joseph Horton",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jacob T.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    }
]
export default LocationReviewsData;
